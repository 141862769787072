:root {
    --color-white: #fff;
    --color-black: #000;
    --color-blue-100: #0bf;
    --color-blue-200: #3b86f7;
    --color-blue-300: #08f;
    --color-blue-400: #07f;
    --color-blue-500: #066bff;
    --color-gray-100: #fafafa;
    --color-gray-200: #f8f8f8;
    --color-gray-300: #f6f6f6;
    --color-gray-400: #f2f2f7;
    --color-gray-500: #efefef;
    --color-gray-600: #e4e4e4;
    --color-gray-700: #949494;
    --color-gray-800: #8e8e93;
    --color-gray-900: #646464;
    --color-black-100: #1c1c1e;
    --color-black-200: #0c0c0c;
    --color-red-100: #fa233b;
    --color-red-200: #fa243c;
    --color-red-300: red;
    --color-red-400: #d00000;
    --color-yellow-100: #e9df2d;
    --color-sapphire: #0324c8;
    --page-max-width: 1340px
}

.AlbumItem-module_mainColumn__wZeYn {
    display: flex;
    flex-direction: column;
    --max-width: 276px
}

.AlbumItem-module_squareCover__Zqywj {
    display: flex;
    width: 276px;
    height: 276px;
    border-radius: 6px;
    border: 1px solid rgba(36,36,36,.3)
}

.AlbumItem-module_isPlaylistLabel__sljzV {
    display: flex;
    margin-top: 15px;
    margin-bottom: 12px
}

.AlbumItem-module_titleLabel__uOZaI {
    margin-bottom: 6px;
    max-width: var(--max-width)
}

.AlbumItem-module_creatorLabel__5YY3L {
    max-width: var(--max-width);
    margin-bottom: 16px
}

.AlbumItem-module_badge__NmoJV {
    display: flex;
    border: 0;
    padding: 0;
    margin: 0;
    width: 73px;
    height: 24px;
    background-color: var(--color-black);
    border-radius: 24px;
    align-self: start;
    cursor: pointer
}

.AlbumItem-module_appleMusicIcon__1Hn7H {
    margin-top: -13px;
    margin-left: 11px;
    width: 50px;
    height: 50px;
    fill: var(--color-white)
}

.AlbumItem-module_badgeContainer__-lI91 {
    z-index: 10
}

@font-face {
    font-family: NeueMontreal;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://ci.assets.shazam.com/website-storybook/fonts/neuemontreal/NeueMontreal-Regular.woff2) format("woff2")
}

@font-face {
    font-family: NeueMontreal;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://ci.assets.shazam.com/website-storybook/fonts/neuemontreal/NeueMontreal-Medium.woff2) format("woff2")
}

@font-face {
    font-family: NeueMontreal;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://ci.assets.shazam.com/website-storybook/fonts/neuemontreal/NeueMontreal-Bold.woff2) format("woff2")
}

.Text-module_fontFamily__cQFwR {
    font-family: NeueMontreal,Arial,sans-serif;
    letter-spacing: .5px
}

.Text-module_fontWeightNormal__kB6Wg {
    font-weight: 400
}

.Text-module_fontWeightBold__4NHce {
    font-weight: 700
}

.Text-module_fontWeight500__iJDFU {
    font-weight: 500
}

.Text-module_fontWeight600__VItmz {
    font-weight: 600
}

.Text-module_text-blue-100__o-Kpz {
    color: var(--color-blue-100)
}

.Text-module_text-blue-200__FNryA {
    color: var(--color-blue-200)
}

.Text-module_text-blue-300__NsFo2 {
    color: var(--color-blue-300)
}

.Text-module_text-blue-400__JmhaZ {
    color: var(--color-blue-400)
}

.Text-module_text-blue-500__HdD9R {
    color: var(--color-blue-500)
}

.Text-module_text-gray-100__8N0IZ {
    color: var(--color-gray-100)
}

.Text-module_text-gray-200__iB6rL {
    color: var(--color-gray-200)
}

.Text-module_text-gray-300__9LOE7 {
    color: var(--color-gray-300)
}

.Text-module_text-gray-400__7WUbj {
    color: var(--color-gray-400)
}

.Text-module_text-gray-500__T5Dab {
    color: var(--color-gray-500)
}

.Text-module_text-gray-600__lAVuE {
    color: var(--color-gray-600)
}

.Text-module_text-gray-700__98Jg4 {
    color: var(--color-gray-700)
}

.Text-module_text-gray-800__u3eW0 {
    color: var(--color-gray-800)
}

.Text-module_text-gray-900__Qcj0F {
    color: var(--color-gray-900)
}

.Text-module_text-black-100__KoE5v {
    color: var(--color-black-100)
}

.Text-module_text-black-200__8cY0O {
    color: var(--color-black-200)
}

.Text-module_text-red-100__RR5uH {
    color: var(--color-red-100)
}

.Text-module_text-red-200__GjwNC {
    color: var(--color-red-200)
}

.Text-module_text-red-300__IuvNu {
    color: var(--color-red-300)
}

.Text-module_text-red-400__J-gBe {
    color: var(--color-red-400)
}

.Text-module_text-yellow-100__Chpdw {
    color: var(--color-yellow-100)
}

.Text-module_text-white__l-SDK {
    color: var(--color-white)
}

.Text-module_text-black__mkuUo {
    color: var(--color-black)
}

.Text-module_textTransformUppercase__9zvQI {
    text-transform: uppercase
}

.Text-module_textDecorationNone__lcqWL {
    text-decoration: none
}

.Text-module_textOverflow__ZC8wi {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.Text-module_withSVG__8vXR9 {
    display: flex;
    gap: 5px;
    align-items: center
}

.Text-module_headingReset__Mn-tB {
    margin: 0
}

.Text-post-module_size-3xs__lejpF {
    font-size: 8px
}

.Text-post-module_size-2xs__Mcmqj {
    font-size: 10px
}

.Text-post-module_size-extraSmall__KyMNP {
    font-size: 12px
}

.Text-post-module_size-small__VmN2x {
    font-size: 14px
}

.Text-post-module_size-base__o144k {
    font-size: 16px
}

.Text-post-module_size-large__VtqqW {
    font-size: 18px
}

.Text-post-module_size-extraLarge__IDy6p {
    font-size: 20px
}

.Text-post-module_size-2xl__hLsb8 {
    font-size: 24px
}

.Text-post-module_size-3xl__rvYmn {
    font-size: 26px
}

.Text-post-module_size-4xl__GBNRC {
    font-size: 32px
}

.Text-post-module_size-5xl__1x678 {
    font-size: 50px
}

@media screen and (min-width: 0px) {
    .Text-post-module_size-xs-3xs__MuR8h {
        font-size:8px
    }

    .Text-post-module_size-xs-2xs__KeHzS {
        font-size: 10px
    }

    .Text-post-module_size-xs-extraSmall__IHiVg {
        font-size: 12px
    }

    .Text-post-module_size-xs-small__FVCO- {
        font-size: 14px
    }

    .Text-post-module_size-xs-base__znhij {
        font-size: 16px
    }

    .Text-post-module_size-xs-large__ENCiJ {
        font-size: 18px
    }

    .Text-post-module_size-xs-extraLarge__lbiZK {
        font-size: 20px
    }

    .Text-post-module_size-xs-2xl__D1QL- {
        font-size: 24px
    }

    .Text-post-module_size-xs-3xl__v080K {
        font-size: 26px
    }

    .Text-post-module_size-xs-4xl__cRb6X {
        font-size: 32px
    }

    .Text-post-module_size-xs-5xl__4-zu6 {
        font-size: 50px
    }
}

@media screen and (min-width: 640px) {
    .Text-post-module_size-sm-3xs__RjawV {
        font-size:8px
    }

    .Text-post-module_size-sm-2xs__6jULO {
        font-size: 10px
    }

    .Text-post-module_size-sm-extraSmall__ln9CE {
        font-size: 12px
    }

    .Text-post-module_size-sm-small__T-MWH {
        font-size: 14px
    }

    .Text-post-module_size-sm-base__WqIMd {
        font-size: 16px
    }

    .Text-post-module_size-sm-large__mUQRt {
        font-size: 18px
    }

    .Text-post-module_size-sm-extraLarge__AUtfF {
        font-size: 20px
    }

    .Text-post-module_size-sm-2xl__bI-N6 {
        font-size: 24px
    }

    .Text-post-module_size-sm-3xl__CR26D {
        font-size: 26px
    }

    .Text-post-module_size-sm-4xl__lGU9b {
        font-size: 32px
    }

    .Text-post-module_size-sm-5xl__5PQuI {
        font-size: 50px
    }
}

@media screen and (min-width: 768px) {
    .Text-post-module_size-md-3xs__RVJmO {
        font-size:8px
    }

    .Text-post-module_size-md-2xs__hx9Cs {
        font-size: 10px
    }

    .Text-post-module_size-md-extraSmall__KAc6P {
        font-size: 12px
    }

    .Text-post-module_size-md-small__sgr5a {
        font-size: 14px
    }

    .Text-post-module_size-md-base__H-EXQ {
        font-size: 16px
    }

    .Text-post-module_size-md-large__4coGy {
        font-size: 18px
    }

    .Text-post-module_size-md-extraLarge__Q6PUi {
        font-size: 20px
    }

    .Text-post-module_size-md-2xl__t--bt {
        font-size: 24px
    }

    .Text-post-module_size-md-3xl__g9Mo5 {
        font-size: 26px
    }

    .Text-post-module_size-md-4xl__tBqJl {
        font-size: 32px
    }

    .Text-post-module_size-md-5xl__VF-gv {
        font-size: 50px
    }
}

@media screen and (min-width: 1024px) {
    .Text-post-module_size-lg-3xs__SczZZ {
        font-size:8px
    }

    .Text-post-module_size-lg-2xs__VClH6 {
        font-size: 10px
    }

    .Text-post-module_size-lg-extraSmall__od-Mv {
        font-size: 12px
    }

    .Text-post-module_size-lg-small__TT5LO {
        font-size: 14px
    }

    .Text-post-module_size-lg-base__xBcpz {
        font-size: 16px
    }

    .Text-post-module_size-lg-large__GY53D {
        font-size: 18px
    }

    .Text-post-module_size-lg-extraLarge__73f00 {
        font-size: 20px
    }

    .Text-post-module_size-lg-2xl__cYq-Z {
        font-size: 24px
    }

    .Text-post-module_size-lg-3xl__zil8Y {
        font-size: 26px
    }

    .Text-post-module_size-lg-4xl__uKAZh {
        font-size: 32px
    }

    .Text-post-module_size-lg-5xl__adOMU {
        font-size: 50px
    }
}

@media screen and (min-width: 1280px) {
    .Text-post-module_size-xl-3xs__alnwE {
        font-size:8px
    }

    .Text-post-module_size-xl-2xs__DbizV {
        font-size: 10px
    }

    .Text-post-module_size-xl-extraSmall__SYsJo {
        font-size: 12px
    }

    .Text-post-module_size-xl-small__FYvVb {
        font-size: 14px
    }

    .Text-post-module_size-xl-base__U3sk3 {
        font-size: 16px
    }

    .Text-post-module_size-xl-large__vuw7C {
        font-size: 18px
    }

    .Text-post-module_size-xl-extraLarge__QcudF {
        font-size: 20px
    }

    .Text-post-module_size-xl-2xl__oEFjG {
        font-size: 24px
    }

    .Text-post-module_size-xl-3xl__rji2N {
        font-size: 26px
    }

    .Text-post-module_size-xl-4xl__vTYAl {
        font-size: 32px
    }

    .Text-post-module_size-xl-5xl__Xv3ys {
        font-size: 50px
    }
}

@media screen and (min-width: 1536px) {
    .Text-post-module_size-xxl-3xs__qHufp {
        font-size:8px
    }

    .Text-post-module_size-xxl-2xs__8nhDM {
        font-size: 10px
    }

    .Text-post-module_size-xxl-extraSmall__dZRIS {
        font-size: 12px
    }

    .Text-post-module_size-xxl-small__okUEX {
        font-size: 14px
    }

    .Text-post-module_size-xxl-base__eNLjM {
        font-size: 16px
    }

    .Text-post-module_size-xxl-large__BxpeY {
        font-size: 18px
    }

    .Text-post-module_size-xxl-extraLarge__vVkI3 {
        font-size: 20px
    }

    .Text-post-module_size-xxl-2xl__1w-WS {
        font-size: 24px
    }

    .Text-post-module_size-xxl-3xl__u2BqK {
        font-size: 26px
    }

    .Text-post-module_size-xxl-4xl__TAlxa {
        font-size: 32px
    }

    .Text-post-module_size-xxl-5xl__lVSpn {
        font-size: 50px
    }
}

.ImageDynamic-post-module_container__nme93 {
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 0
}

.ImageDynamic-post-module_container-radius-base__R44-u {
    border-radius: 8px
}

.ImageDynamic-post-module_container-radius-none__ELyuh {
    border-radius: 0
}

.ImageDynamic-post-module_container-radius-circle__ED2iJ {
    border-radius: 50%
}

.ImageDynamic-post-module_image__yGTB1 {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%
}

.ImageDynamic-post-module_shape-square__nq6gC {
    aspect-ratio: 1/1
}

.ImageDynamic-post-module_shape-portrait__ymOv5 {
    aspect-ratio: 23/33
}

.ImageDynamic-post-module_shape-landscape__1qprg {
    aspect-ratio: 23/16
}

.ImageDynamic-post-module_fade-in__MjGSB {
    opacity: 0;
    transition: opacity .4s ease-in-out
}

.ImageDynamic-post-module_loaded__KQ8A7 {
    opacity: 1
}

@media screen and (min-width: 0px) {
    .ImageDynamic-post-module_container-radius-xs-base__Ugtzs {
        border-radius:8px
    }

    .ImageDynamic-post-module_container-radius-xs-circle__SAirJ {
        border-radius: 50%
    }

    .ImageDynamic-post-module_container-radius-xs-landscape__Ef0ss {
        border-radius: $(ar)
    }
}

@media screen and (min-width: 640px) {
    .ImageDynamic-post-module_container-radius-sm-base__VLzxm {
        border-radius:8px
    }

    .ImageDynamic-post-module_container-radius-sm-circle__Gegdk {
        border-radius: 50%
    }

    .ImageDynamic-post-module_container-radius-sm-landscape__TurqO {
        border-radius: $(ar)
    }
}

@media screen and (min-width: 768px) {
    .ImageDynamic-post-module_container-radius-md-base__-iGFS {
        border-radius:8px
    }

    .ImageDynamic-post-module_container-radius-md-circle__GBSRa {
        border-radius: 50%
    }

    .ImageDynamic-post-module_container-radius-md-landscape__xWUrd {
        border-radius: $(ar)
    }
}

@media screen and (min-width: 1024px) {
    .ImageDynamic-post-module_container-radius-lg-base__kbiTs {
        border-radius:8px
    }

    .ImageDynamic-post-module_container-radius-lg-circle__5S-y6 {
        border-radius: 50%
    }

    .ImageDynamic-post-module_container-radius-lg-landscape__MnyLW {
        border-radius: $(ar)
    }
}

@media screen and (min-width: 1280px) {
    .ImageDynamic-post-module_container-radius-xl-base__Zj4U2 {
        border-radius:8px
    }

    .ImageDynamic-post-module_container-radius-xl-circle__DvGR4 {
        border-radius: 50%
    }

    .ImageDynamic-post-module_container-radius-xl-landscape__Uk4Bn {
        border-radius: $(ar)
    }
}

@media screen and (min-width: 1536px) {
    .ImageDynamic-post-module_container-radius-xxl-base__9Mkof {
        border-radius:8px
    }

    .ImageDynamic-post-module_container-radius-xxl-circle__gJqYI {
        border-radius: 50%
    }

    .ImageDynamic-post-module_container-radius-xxl-landscape__d6jCo {
        border-radius: $(ar)
    }
}

@media screen and (min-width: 0px) {
    .ImageDynamic-post-module_container-shape-xs-square__h7uRU {
        aspect-ratio:1/1
    }

    .ImageDynamic-post-module_container-shape-xs-portrait__Oufhb {
        aspect-ratio: 23/33
    }

    .ImageDynamic-post-module_container-shape-xs-landscape__dBM5D {
        aspect-ratio: 23/16
    }
}

@media screen and (min-width: 640px) {
    .ImageDynamic-post-module_container-shape-sm-square__-asmW {
        aspect-ratio:1/1
    }

    .ImageDynamic-post-module_container-shape-sm-portrait__oEhPq {
        aspect-ratio: 23/33
    }

    .ImageDynamic-post-module_container-shape-sm-landscape__i92HC {
        aspect-ratio: 23/16
    }
}

@media screen and (min-width: 768px) {
    .ImageDynamic-post-module_container-shape-md-square__eo8FU {
        aspect-ratio:1/1
    }

    .ImageDynamic-post-module_container-shape-md-portrait__8LxCs {
        aspect-ratio: 23/33
    }

    .ImageDynamic-post-module_container-shape-md-landscape__7IznC {
        aspect-ratio: 23/16
    }
}

@media screen and (min-width: 1024px) {
    .ImageDynamic-post-module_container-shape-lg-square__mxkbE {
        aspect-ratio:1/1
    }

    .ImageDynamic-post-module_container-shape-lg-portrait__dQ4M5 {
        aspect-ratio: 23/33
    }

    .ImageDynamic-post-module_container-shape-lg-landscape__vtack {
        aspect-ratio: 23/16
    }
}

@media screen and (min-width: 1280px) {
    .ImageDynamic-post-module_container-shape-xl-square__ovk-S {
        aspect-ratio:1/1
    }

    .ImageDynamic-post-module_container-shape-xl-portrait__MJfoE {
        aspect-ratio: 23/33
    }

    .ImageDynamic-post-module_container-shape-xl-landscape__E7ojA {
        aspect-ratio: 23/16
    }
}

@media screen and (min-width: 1536px) {
    .ImageDynamic-post-module_container-shape-xxl-square__enJsF {
        aspect-ratio:1/1
    }

    .ImageDynamic-post-module_container-shape-xxl-portrait__QE7xi {
        aspect-ratio: 23/33
    }

    .ImageDynamic-post-module_container-shape-xxl-landscape__j6t0X {
        aspect-ratio: 23/16
    }
}

.ImageDynamic-post-module_container-size-40x40__Vqi7q {
    width: 40px;
    height: 40px
}

.ImageDynamic-post-module_container-size-60x60__3R7Sr {
    width: 60px;
    height: 60px
}

.ImageDynamic-post-module_container-size-75x75__cpgut {
    width: 75px;
    height: 75px
}

.ImageDynamic-post-module_container-size-95x95__V8PR0 {
    width: 95px;
    height: 95px
}

.ImageDynamic-post-module_container-size-128x128__Uv6WU {
    width: 128px;
    height: 128px
}

.ImageDynamic-post-module_container-size-145x145__2Oy28 {
    width: 145px;
    height: 145px
}

.ImageDynamic-post-module_container-size-210x210__OGAVm {
    width: 210px;
    height: 210px
}

.ImageDynamic-post-module_container-size-248x248__scJ8s {
    width: 248px;
    height: 248px
}

.ImageDynamic-post-module_container-size-255x255__cGK82 {
    width: 255px;
    height: 255px
}

.ImageDynamic-post-module_container-size-276x276__AkowO {
    width: 276px;
    height: 276px
}

.ImageDynamic-post-module_container-size-282x282__7n7wN {
    width: 282px;
    height: 282px
}

.ImageDynamic-post-module_container-size-290x290__-y3Tg {
    width: 290px;
    height: 290px
}

.ImageDynamic-post-module_container-size-326x326__r6Ayk {
    width: 326px;
    height: 326px
}

.ImageDynamic-post-module_container-size-400x400__kFNG7 {
    width: 400px;
    height: 400px
}

.ImageDynamic-post-module_container-size-420x420__SVDjt {
    width: 420px;
    height: 420px
}

@media screen and (min-width: 0px) {
    .ImageDynamic-post-module_container-size-xs-40x40__jiQpb {
        width:40px;
        height: 40px
    }

    .ImageDynamic-post-module_container-size-xs-60x60__Fd-RO {
        width: 60px;
        height: 60px
    }

    .ImageDynamic-post-module_container-size-xs-75x75__yjZcb {
        width: 75px;
        height: 75px
    }

    .ImageDynamic-post-module_container-size-xs-95x95__tIz2T {
        width: 95px;
        height: 95px
    }

    .ImageDynamic-post-module_container-size-xs-128x128__LQyhr {
        width: 128px;
        height: 128px
    }

    .ImageDynamic-post-module_container-size-xs-145x145__W2wUl {
        width: 145px;
        height: 145px
    }

    .ImageDynamic-post-module_container-size-xs-210x210__MalIS {
        width: 210px;
        height: 210px
    }

    .ImageDynamic-post-module_container-size-xs-248x248__9Ka6d {
        width: 248px;
        height: 248px
    }

    .ImageDynamic-post-module_container-size-xs-255x255__-4jzO {
        width: 255px;
        height: 255px
    }

    .ImageDynamic-post-module_container-size-xs-276x276__72ShY {
        width: 276px;
        height: 276px
    }

    .ImageDynamic-post-module_container-size-xs-282x282__BY4Bz {
        width: 282px;
        height: 282px
    }

    .ImageDynamic-post-module_container-size-xs-290x290__qdn4D {
        width: 290px;
        height: 290px
    }

    .ImageDynamic-post-module_container-size-xs-400x400__W3m-a {
        width: 400px;
        height: 400px
    }

    .ImageDynamic-post-module_container-size-xs-420x420__H-tYE {
        width: 420px;
        height: 420px
    }
}

@media screen and (min-width: 640px) {
    .ImageDynamic-post-module_container-size-sm-40x40__4iSg9 {
        width:40px;
        height: 40px
    }

    .ImageDynamic-post-module_container-size-sm-60x60__AyaRX {
        width: 60px;
        height: 60px
    }

    .ImageDynamic-post-module_container-size-sm-75x75__Vp-fz {
        width: 75px;
        height: 75px
    }

    .ImageDynamic-post-module_container-size-sm-95x95__RuZAG {
        width: 95px;
        height: 95px
    }

    .ImageDynamic-post-module_container-size-sm-128x128__khseQ {
        width: 128px;
        height: 128px
    }

    .ImageDynamic-post-module_container-size-sm-145x145__5UJ6N {
        width: 145px;
        height: 145px
    }

    .ImageDynamic-post-module_container-size-sm-210x210__0q3iF {
        width: 210px;
        height: 210px
    }

    .ImageDynamic-post-module_container-size-sm-248x248__kW84b {
        width: 248px;
        height: 248px
    }

    .ImageDynamic-post-module_container-size-sm-255x255__9dKTH {
        width: 255px;
        height: 255px
    }

    .ImageDynamic-post-module_container-size-sm-276x276__MGxOo {
        width: 276px;
        height: 276px
    }

    .ImageDynamic-post-module_container-size-sm-282x282__O42Qz {
        width: 282px;
        height: 282px
    }

    .ImageDynamic-post-module_container-size-sm-290x290__mc3pN {
        width: 290px;
        height: 290px
    }

    .ImageDynamic-post-module_container-size-sm-400x400__afrH6 {
        width: 400px;
        height: 400px
    }

    .ImageDynamic-post-module_container-size-sm-420x420__GUliy {
        width: 420px;
        height: 420px
    }
}

@media screen and (min-width: 768px) {
    .ImageDynamic-post-module_container-size-md-40x40__DRawQ {
        width:40px;
        height: 40px
    }

    .ImageDynamic-post-module_container-size-md-60x60__3PT8r {
        width: 60px;
        height: 60px
    }

    .ImageDynamic-post-module_container-size-md-75x75__-UMOj {
        width: 75px;
        height: 75px
    }

    .ImageDynamic-post-module_container-size-md-95x95__b53Cl {
        width: 95px;
        height: 95px
    }

    .ImageDynamic-post-module_container-size-md-128x128__P2fJW {
        width: 128px;
        height: 128px
    }

    .ImageDynamic-post-module_container-size-md-145x145__Cfffu {
        width: 145px;
        height: 145px
    }

    .ImageDynamic-post-module_container-size-md-210x210__ZHmBS {
        width: 210px;
        height: 210px
    }

    .ImageDynamic-post-module_container-size-md-248x248__2v817 {
        width: 248px;
        height: 248px
    }

    .ImageDynamic-post-module_container-size-md-255x255__Wpm4v {
        width: 255px;
        height: 255px
    }

    .ImageDynamic-post-module_container-size-md-276x276__RK2Ah {
        width: 276px;
        height: 276px
    }

    .ImageDynamic-post-module_container-size-md-282x282__3fnHV {
        width: 282px;
        height: 282px
    }

    .ImageDynamic-post-module_container-size-md-290x290__lQPg7 {
        width: 290px;
        height: 290px
    }

    .ImageDynamic-post-module_container-size-md-400x400__K9Nhj {
        width: 400px;
        height: 400px
    }

    .ImageDynamic-post-module_container-size-md-420x420__ql3VQ {
        width: 420px;
        height: 420px
    }
}

@media screen and (min-width: 1024px) {
    .ImageDynamic-post-module_container-size-lg-40x40__GCj-z {
        width:40px;
        height: 40px
    }

    .ImageDynamic-post-module_container-size-lg-60x60__MABxF {
        width: 60px;
        height: 60px
    }

    .ImageDynamic-post-module_container-size-lg-75x75__EjULq {
        width: 75px;
        height: 75px
    }

    .ImageDynamic-post-module_container-size-lg-95x95__jMhzQ {
        width: 95px;
        height: 95px
    }

    .ImageDynamic-post-module_container-size-lg-128x128__6vV31 {
        width: 128px;
        height: 128px
    }

    .ImageDynamic-post-module_container-size-lg-145x145__NIscu {
        width: 145px;
        height: 145px
    }

    .ImageDynamic-post-module_container-size-lg-210x210__E7425 {
        width: 210px;
        height: 210px
    }

    .ImageDynamic-post-module_container-size-lg-248x248__vh7M4 {
        width: 248px;
        height: 248px
    }

    .ImageDynamic-post-module_container-size-lg-255x255__37ZZH {
        width: 255px;
        height: 255px
    }

    .ImageDynamic-post-module_container-size-lg-276x276__HIDW5 {
        width: 276px;
        height: 276px
    }

    .ImageDynamic-post-module_container-size-lg-282x282__s8rsf {
        width: 282px;
        height: 282px
    }

    .ImageDynamic-post-module_container-size-lg-290x290__yymb2 {
        width: 290px;
        height: 290px
    }

    .ImageDynamic-post-module_container-size-lg-400x400__7SaDv {
        width: 400px;
        height: 400px
    }

    .ImageDynamic-post-module_container-size-lg-420x420__c84qy {
        width: 420px;
        height: 420px
    }
}

@media screen and (min-width: 1280px) {
    .ImageDynamic-post-module_container-size-xl-40x40__Ch-m5 {
        width:40px;
        height: 40px
    }

    .ImageDynamic-post-module_container-size-xl-60x60__tnFU5 {
        width: 60px;
        height: 60px
    }

    .ImageDynamic-post-module_container-size-xl-75x75__Yydqh {
        width: 75px;
        height: 75px
    }

    .ImageDynamic-post-module_container-size-xl-95x95__1sEMK {
        width: 95px;
        height: 95px
    }

    .ImageDynamic-post-module_container-size-xl-128x128__UMixa {
        width: 128px;
        height: 128px
    }

    .ImageDynamic-post-module_container-size-xl-145x145__F-9vq {
        width: 145px;
        height: 145px
    }

    .ImageDynamic-post-module_container-size-xl-210x210__6NxRB {
        width: 210px;
        height: 210px
    }

    .ImageDynamic-post-module_container-size-xl-248x248__ssP3g {
        width: 248px;
        height: 248px
    }

    .ImageDynamic-post-module_container-size-xl-255x255__lyJe0 {
        width: 255px;
        height: 255px
    }

    .ImageDynamic-post-module_container-size-xl-276x276__kmVLn {
        width: 276px;
        height: 276px
    }

    .ImageDynamic-post-module_container-size-xl-282x282__oGFZL {
        width: 282px;
        height: 282px
    }

    .ImageDynamic-post-module_container-size-xl-290x290__Ejx-i {
        width: 290px;
        height: 290px
    }

    .ImageDynamic-post-module_container-size-xl-400x400__F8Vhp {
        width: 400px;
        height: 400px
    }

    .ImageDynamic-post-module_container-size-xl-420x420__yldp2 {
        width: 420px;
        height: 420px
    }
}

@media screen and (min-width: 1536px) {
    .ImageDynamic-post-module_container-size-xxl-40x40__h1sf4 {
        width:40px;
        height: 40px
    }

    .ImageDynamic-post-module_container-size-xxl-60x60__OcThA {
        width: 60px;
        height: 60px
    }

    .ImageDynamic-post-module_container-size-xxl-75x75__EfyEN {
        width: 75px;
        height: 75px
    }

    .ImageDynamic-post-module_container-size-xxl-95x95__US4Sx {
        width: 95px;
        height: 95px
    }

    .ImageDynamic-post-module_container-size-xxl-128x128__w3YDY {
        width: 128px;
        height: 128px
    }

    .ImageDynamic-post-module_container-size-xxl-145x145__EL5YI {
        width: 145px;
        height: 145px
    }

    .ImageDynamic-post-module_container-size-xxl-210x210__pvQur {
        width: 210px;
        height: 210px
    }

    .ImageDynamic-post-module_container-size-xxl-248x248__tcHHs {
        width: 248px;
        height: 248px
    }

    .ImageDynamic-post-module_container-size-xxl-255x255__l-GEQ {
        width: 255px;
        height: 255px
    }

    .ImageDynamic-post-module_container-size-xxl-276x276__elpHs {
        width: 276px;
        height: 276px
    }

    .ImageDynamic-post-module_container-size-xxl-282x282__UF0ok {
        width: 282px;
        height: 282px
    }

    .ImageDynamic-post-module_container-size-xxl-290x290__xKj-o {
        width: 290px;
        height: 290px
    }

    .ImageDynamic-post-module_container-size-xxl-400x400__OiyGm {
        width: 400px;
        height: 400px
    }

    .ImageDynamic-post-module_container-size-xxl-420x420__9sByx {
        width: 420px;
        height: 420px
    }
}

.ImageDynamic-post-module_container-size-90x120__2PEA5 {
    width: 90px;
    height: 120px
}

.ImageDynamic-post-module_container-size-100x120__Y3cJI {
    width: 100px;
    height: 120px
}

.ImageDynamic-post-module_container-size-140x200__GQc2g {
    width: 140px;
    height: 200px
}

.ImageDynamic-post-module_container-size-260x345__k46GX {
    width: 260px;
    height: 345px
}

@media screen and (min-width: 0px) {
    .ImageDynamic-post-module_container-size-xs-90x100__UVYLb {
        width:90px;
        height: 100px
    }

    .ImageDynamic-post-module_container-size-xs-120x120__rqtFZ {
        width: 120px;
        height: 120px
    }
}

@media screen and (min-width: 640px) {
    .ImageDynamic-post-module_container-size-sm-90x100__aSl2m {
        width:90px;
        height: 100px
    }

    .ImageDynamic-post-module_container-size-sm-120x120__zEqVc {
        width: 120px;
        height: 120px
    }
}

@media screen and (min-width: 768px) {
    .ImageDynamic-post-module_container-size-md-90x100__yMxSw {
        width:90px;
        height: 100px
    }

    .ImageDynamic-post-module_container-size-md-120x120__JHnI8 {
        width: 120px;
        height: 120px
    }
}

@media screen and (min-width: 1024px) {
    .ImageDynamic-post-module_container-size-lg-90x100__-VOa4 {
        width:90px;
        height: 100px
    }

    .ImageDynamic-post-module_container-size-lg-120x120__nr1Fi {
        width: 120px;
        height: 120px
    }
}

@media screen and (min-width: 1280px) {
    .ImageDynamic-post-module_container-size-xl-90x100__SVgbn {
        width:90px;
        height: 100px
    }

    .ImageDynamic-post-module_container-size-xl-120x120__AazbI {
        width: 120px;
        height: 120px
    }
}

@media screen and (min-width: 1536px) {
    .ImageDynamic-post-module_container-size-xxl-90x100__wKWzb {
        width:90px;
        height: 100px
    }

    .ImageDynamic-post-module_container-size-xxl-120x120__hm69V {
        width: 120px;
        height: 120px
    }
}

.ImageDynamic-post-module_container-size-115x80__VyWpj {
    width: 115px;
    height: 80px
}

@media screen and (min-width: 0px) {
    .ImageDynamic-post-module_container-size-xs-115x80__107qy {
        width:115px;
        height: 80px
    }
}

@media screen and (min-width: 640px) {
    .ImageDynamic-post-module_container-size-sm-115x80__5Pco2 {
        width:115px;
        height: 80px
    }
}

@media screen and (min-width: 768px) {
    .ImageDynamic-post-module_container-size-md-115x80__JRSrH {
        width:115px;
        height: 80px
    }
}

@media screen and (min-width: 1024px) {
    .ImageDynamic-post-module_container-size-lg-115x80__XcIht {
        width:115px;
        height: 80px
    }
}

@media screen and (min-width: 1280px) {
    .ImageDynamic-post-module_container-size-xl-115x80__0sj4u {
        width:115px;
        height: 80px
    }
}

@media screen and (min-width: 1536px) {
    .ImageDynamic-post-module_container-size-xxl-115x80__SA84G {
        width:115px;
        height: 80px
    }
}

.ContainerLink-module_container__nUvQc {
    position: relative
}

.ContainerLink-module_containerLinkElement__EOJ6S {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block
}

.AlbumList-module_scrollbox__8WVL8 {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    overscroll-behavior-x: none;
    padding: 5px;
    width: 100%
}

.AlbumList-module_itemSpacing__KNplW {
    margin: 5px 10px 5px 5px
}

.AlbumMosaic-module_top3Container__KoAAZ {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1
}

.AlbumMosaic-module_shzPartialTop3__O58Iz.AlbumMosaic-module_isResponseView__5QOAD {
    --track-image-size: 120px
}

.AlbumMosaic-module_shzPartialTop3__O58Iz {
    position: absolute;
    top: calc(calc(var(--track-image-size) * 2 + 10px) * -.7);
    right: calc(calc(var(--track-image-size) * 2 + 10px) / 2.7);
    z-index: -1;
    transform: rotate(-45deg);
    --track-image-size: 165px
}

.AlbumMosaic-module_shzPartialTop3__O58Iz .AlbumMosaic-module_trackImage__YZkZY {
    position: absolute;
    width: var(--track-image-size);
    height: var(--track-image-size);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border: 1px solid hsla(0,0%,100%,.1)
}

.AlbumMosaic-module_shzPartialTop3__O58Iz .AlbumMosaic-module_trackImage__YZkZY:first-child {
    top: calc(var(--track-image-size) + 10px);
    right: calc(var(--track-image-size) + 10px)
}

.AlbumMosaic-module_shzPartialTop3__O58Iz .AlbumMosaic-module_trackImage__YZkZY:nth-child(2) {
    top: 0;
    right: calc(var(--track-image-size) + 10px)
}

.AlbumMosaic-module_shzPartialTop3__O58Iz .AlbumMosaic-module_trackImage__YZkZY:nth-child(3) {
    top: calc(var(--track-image-size) + 10px);
    right: 0
}

.AlbumMosaic-module_shzPartialTop3__O58Iz .AlbumMosaic-module_trackImage__YZkZY>div {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover
}

@media (min-width: 768px) {
    .AlbumMosaic-module_shzPartialTop3__O58Iz.AlbumMosaic-module_isResponseView__5QOAD {
        --track-image-size:165px
    }
}

.ArtistHeroBanner-module_container__5IhM5 {
    width: 100%
}

.ArtistHeroBanner-module_imageContainer__dsBkI {
    width: 100%;
    height: auto;
    min-height: 180px;
    position: relative
}

.ArtistHeroBanner-module_artistImage__apfIV {
    height: 393px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.ArtistHeroBanner-module_gradientOverlayFallback__cEH5R,.ArtistHeroBanner-module_gradientOverlay__iTD-L {
    background: linear-gradient(transparent,80%,rgba(0,0,0,.8));
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 4px;
    z-index: 1;
    display: grid;
    grid-template-rows: .1fr 1fr;
    align-items: flex-end
}

.ArtistHeroBanner-module_gradientOverlayFallback__cEH5R {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: flex-end
}

.ArtistHeroBanner-module_desktopSubtext__kYdMe {
    display: none
}

.ArtistHeroBanner-module_mobileSubtextContainer__UTiO8 {
    padding: 32px;
    background-color: #fff;
    border-radius: 16px;
    text-align: center
}

.ArtistHeroBanner-module_mobileSCUpcomingShows__SNEc2 {
    padding: 20px;
    text-align: left
}

.ArtistHeroBanner-module_richTextContainer__lcvH2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    margin-top: 8px;
    width: -moz-fit-content;
    width: fit-content
}

.ArtistHeroBanner-module_artistBannerButtonContainer__RS6Kr {
    display: flex;
    position: relative;
    padding: 16px;
    gap: 2px;
    justify-content: flex-end
}

.ArtistHeroBanner-module_artistText__lY8rJ {
    margin-right: 4px
}

.ArtistHeroBanner-module_artistNameAndSubtext__XqCVH {
    flex-grow: 1;
    text-align: left;
    display: flex;
    flex-direction: column
}

.ArtistHeroBanner-module_fallbackImageContainer__vrLmP {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 393px;
    justify-content: space-between;
    position: relative
}

.ArtistHeroBanner-module_artistImageFallback__idjae {
    width: 100%;
    height: auto
}

@media (min-width: 833px) {
    .ArtistHeroBanner-module_container__5IhM5 {
        padding:0;
        margin: 0;
        max-width: 1128px;
        height: 100%
    }

    .ArtistHeroBanner-module_imageContainer__dsBkI {
        width: 100%;
        height: 100%;
        position: relative
    }

    .ArtistHeroBanner-module_artistImage__apfIV {
        border-radius: 16px;
        width: 100%;
        height: 100%
    }

    .ArtistHeroBanner-module_gradientOverlayFallback__cEH5R,.ArtistHeroBanner-module_gradientOverlay__iTD-L {
        background: linear-gradient(transparent,80%,rgba(0,0,0,.8));
        position: absolute;
        max-width: 1128px;
        height: 99%;
        bottom: 3px;
        z-index: 1;
        align-items: flex-end;
        border-radius: 16px
    }

    .ArtistHeroBanner-module_gradientOverlayFallback__cEH5R {
        bottom: 0
    }

    .ArtistHeroBanner-module_desktopSubtext__kYdMe {
        display: block
    }

    .ArtistHeroBanner-module_artistButtonContainer__G45XV {
        width: -moz-fit-content;
        width: fit-content;
        margin-top: 2rem;
        margin-left: .5rem
    }

    .ArtistHeroBanner-module_artistBannerButtonContainer__RS6Kr {
        display: flex;
        justify-content: flex-end;
        position: relative
    }

    .ArtistHeroBanner-module_mobileSubtextContainer__UTiO8,.ArtistHeroBanner-module_mobileSubtext__5AmY1 {
        display: none
    }

    .ArtistHeroBanner-module_artistImageFallback__idjae {
        width: 25vw;
        height: auto;
        min-width: 300px;
        max-width: 400px;
        border-radius: 100%;
        margin-bottom: 10vh;
        position: absolute;
        top: 45%;
        transform: translateY(-50%)
    }

    .ArtistHeroBanner-module_fallbackImageContainer__vrLmP {
        height: 568px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative
    }
}

.Button-module_container__WuvCi {
    -moz-appearance: button;
    appearance: button;
    -webkit-appearance: button;
    font-family: NeueMontreal,Arial,sans-serif;
    letter-spacing: .5px;
    color: var(--color-white);
    display: inline-flex;
    gap: 8px;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    text-decoration: none
}

.Button-module_iconPositionLeft__hzVjW {
    flex-direction: row
}

.Button-module_iconPositionRight__p6TEV {
    flex-direction: row-reverse
}

.Button-module_padding2xs__5LeVT {
    gap: 4px;
    padding: 3px 7px 4px 6px
}

.Button-module_paddingxs__MjAdf {
    padding: 5px 15px
}

.Button-module_paddingSmallNarrow__6Cane {
    padding: 10px 15px
}

.Button-module_paddingSmall__HDIGU {
    padding: 10px 20px
}

.Button-module_paddingBaseNarrow__srxKK {
    padding: 15px 20px
}

.Button-module_paddingBase__-mzTv {
    padding: 15px 30px
}

.Button-module_paddingLarge__uGKI8 {
    padding: 20px 40px
}

.Button-module_shapeStandard__mX-F8 {
    border-radius: 8px
}

.Button-module_shapeCircle__L6M49 {
    border-radius: 50%;
    padding: 6px;
    justify-content: center
}

.Button-module_shapeCircle__L6M49.Button-module_paddingxs__MjAdf {
    padding: 5px
}

.Button-module_shapeCircle__L6M49.Button-module_paddingSmallNarrow__6Cane,.Button-module_shapeCircle__L6M49.Button-module_paddingSmall__HDIGU {
    padding: 10px
}

.Button-module_shapeCircle__L6M49.Button-module_paddingBaseNarrow__srxKK,.Button-module_shapeCircle__L6M49.Button-module_paddingBase__-mzTv {
    padding: 15px
}

.Button-module_shapeCircle__L6M49.Button-module_paddingLarge__uGKI8 {
    padding: 20px
}

.Button-module_shapePill__St97o {
    border-radius: 50px
}

.Button-module_displayBlock__9ollp {
    display: block;
    width: 100%
}

.Button-module_displayInlineFlex__-ibXX {
    display: inline-flex
}

.Button-module_displayBlockAnchor__DqUsg {
    display: block;
    width: auto;
    text-align: center
}

.Button-module_label__k1Dkf {
    font-weight: 700
}

.Button-module_variantPrimary__dQC1S {
    background-color: var(--color-blue-500)
}

.Button-module_variantPrimary__dQC1S .Button-module_label__k1Dkf {
    color: var(--color-white)
}

.Button-module_variantSecondary__SEiAJ {
    background-color: var(--color-gray-300)
}

.Button-module_variantSecondary__SEiAJ .Button-module_label__k1Dkf {
    color: var(--color-blue-500)
}

.Button-module_variantAttention__S1Tai {
    background-image: linear-gradient(to bottom,var(--color-red-100),var(--color-red-200)),linear-gradient(to bottom,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))
}

.Button-module_variantAttention__S1Tai .Button-module_label__k1Dkf {
    color: var(--color-white)
}

.Button-module_variantAttentionSecondary__r6Lgy {
    background-color: var(--color-white)
}

.Button-module_variantAttentionSecondary__r6Lgy .Button-module_label__k1Dkf {
    color: var(--color-red-400)
}

.Button-module_variantSapphire__3P-FM {
    background-color: var(--color-sapphire)
}

.Button-module_variantSapphire__3P-FM .Button-module_label__k1Dkf {
    color: var(--color-white)
}

.Button-module_variantSapphireSecondary__lWGvw {
    background-color: var(--color-white)
}

.Button-module_variantSapphireSecondary__lWGvw .Button-module_label__k1Dkf {
    color: var(--color-sapphire)
}

.Button-module_variantSecondaryDark__JiYgw .Button-module_label__k1Dkf {
    color: var(--color-black-100)
}

.Button-module_variantDark__sliEX {
    background-color: var(--color-black-200)
}

.Button-module_variantDark__sliEX .Button-module_label__k1Dkf {
    color: var(--color-white)
}

.Button-module_variantLight__j3-8H {
    background-color: var(--color-white)
}

.Button-module_variantLight__j3-8H .Button-module_label__k1Dkf {
    color: var(--color-black-200)
}

.Button-module_variantOutline__b5a9D {
    background-color: transparent;
    border: 1px solid var(--color-blue-500)
}

.Button-module_variantOutline__b5a9D .Button-module_label__k1Dkf {
    color: var(--color-blue-500)
}

.Button-module_textUppercase__4duG7 .Button-module_label__k1Dkf {
    text-transform: uppercase;
    position: relative;
    top: 1px;
    font-family: arial
}

.Button-module_textSizeSmall__sRX2M {
    font-size: 12px
}

.Button-module_textSizeBase__RUb8y {
    font-size: 14px
}

.Button-module_textSizeLarge__g12Nr {
    font-size: 16px
}

.Button-module_textWeightNormal__y97XS .Button-module_label__k1Dkf {
    font-weight: 400
}

.Button-module_textWeightMedium__WiFZX .Button-module_label__k1Dkf {
    font-weight: 500
}

.Button-module_textWeightBold__IyHKS .Button-module_label__k1Dkf {
    font-weight: 700
}

.ArtistPlaylistCard-module_container__0oF-D,.ArtistPlaylistCard-module_container__0oF-D * {
    box-sizing: border-box
}

.ArtistPlaylistCard-module_container__0oF-D {
    padding: 24px 15px 26px;
    position: relative;
    border-radius: 8px;
    max-height: 170px;
    overflow: hidden;
    display: flex;
    justify-content: center
}

.ArtistPlaylistCard-module_detail__Pf46F {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto
}

.ArtistPlaylistCard-module_editorialNotes__55B6t,.ArtistPlaylistCard-module_title__RnlCr {
    margin-top: 0;
    text-decoration: none
}

.ArtistPlaylistCard-module_title__RnlCr {
    margin-bottom: 5px
}

.ArtistPlaylistCard-module_editorialNotes__55B6t,.ArtistPlaylistCard-module_title__RnlCr {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.ArtistPlaylistCard-module_imageContainer__RRfVO {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    box-shadow: 12px 12px 20px 0 rgba(0,0,0,.1);
    border: 1px solid hsla(0,0%,89%,.2);
    cursor: pointer
}

.ArtistPlaylistCard-module_imageContainerBackground__nqW5Q {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.ArtistPlaylistCard-module_imageContainerBackground__nqW5Q>img {
    filter: blur(8px) brightness(50%);
    transform: scale(3)
}

.ArtistPlaylistCard-module_buttonContainer__uZ4i- {
    display: none
}

@media (min-width: 768px) {
    .ArtistPlaylistCard-module_container__0oF-D {
        padding:60px 40px;
        border-radius: 8px;
        max-width: 557px;
        max-height: 330px
    }
}

@media (min-width: 640px) {
    .ArtistPlaylistCard-module_buttonContainer__uZ4i- {
        display:block
    }
}

.FloatingShazamButton-module_shazamButton__zfuwc {
    position: fixed;
    bottom: 110px;
    right: 40px;
    z-index: 100
}

.FloatingShazamButton-module_buttonContainer__M-Q7w {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #e4e4e4;
    width: 50px;
    height: 50px;
    border-radius: 100%
}

.FloatingShazamButton-module_buttonContainer__M-Q7w:hover {
    transform: scale(1.2)
}

.FloatingShazamButton-module_calloutContainer__Rs3Tz {
    width: -moz-max-content;
    width: max-content;
    min-width: 100px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #e4e4e4;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,.08);
    padding: 20px;
    display: flex;
    flex-direction: column
}

.FloatingShazamButton-module_mainText__D9Xgq {
    margin-bottom: 5px
}

.FloatingShazamButton-module_closeButtonContainer__Bs-Nn {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 2;
    cursor: pointer
}

.MatchInterstitial-module_interstitialContainer__FCFfq {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    text-align: center
}

.ListeningScreen-module_listeningContainer__G9ooK {
    background-image: linear-gradient(180deg,#0bf,#066aff);
    padding: 0 35px
}

.ListeningScreen-module_main__CijmK {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh
}

.ListeningScreen-module_textContainer__v6Bv4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-60%)
}

.ListeningScreen-module_logoHeadingContainer__afGyf {
    height: 50px
}

.ListeningScreen-module_buttonContainer__lu2Hw,.ListeningScreen-module_button__no7du {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 200;
    transform: translate(-50%,-45%)
}

.ListeningScreen-module_listeningCircle__JbO7F {
    background-color: hsla(0,0%,100%,.4);
    opacity: 0;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 150px;
    width: 150px;
    z-index: 99;
    transform: translate(-50%,-50%);
    animation: ListeningScreen-module_growAndFade__BSWgt 2s cubic-bezier(.36,.11,.89,.32) infinite
}

.ListeningScreen-module_cancelContainer__UJmXQ {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%)
}

#ListeningScreen-module_c1__64Arv {
    animation-delay: 0s
}

#ListeningScreen-module_c2__3G3M5 {
    animation-delay: .4s
}

#ListeningScreen-module_c3__BjMnZ {
    animation-delay: .8s
}

#ListeningScreen-module_c4__zH0iF {
    animation-delay: 1.2s
}

@keyframes ListeningScreen-module_growAndFade__BSWgt {
    0% {
        height: 150px;
        width: 150px
    }

    50% {
        opacity: .2
    }

    to {
        height: 600px;
        width: 600px;
        opacity: 0
    }
}

.Modal-module_overlay__jQMv2 {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: background-color .6s cubic-bezier(.4,.2,0,1);
    pointer-events: none;
    display: flex;
    justify-content: center
}

.Modal-module_overlay__jQMv2.Modal-module_isVisible__NrC08 {
    pointer-events: auto;
    background-color: rgba(0,0,0,.9)
}

.Modal-module_overlay__jQMv2.Modal-module_isVisible__NrC08 .Modal-module_modal__7sLa5,.Modal-module_overlay__jQMv2.Modal-module_isVisible__NrC08 .Modal-module_noTitleModal__5E5uL {
    transform: translateY(0)
}

.Modal-module_modal__7sLa5 {
    color: var(--color-black);
    background-color: var(--color-white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}

.Modal-module_modal__7sLa5,.Modal-module_noTitleModal__5E5uL {
    margin-top: 20vh;
    padding: 0;
    width: -moz-fit-content;
    width: fit-content;
    height: 100px;
    transition: transform .6s cubic-bezier(.4,.2,0,1);
    overflow: hidden;
    transform: translateY(200vh);
    height: -moz-fit-content;
    height: fit-content
}

.Modal-module_noTitleModal__5E5uL {
    border-radius: 8px
}

.Modal-module_container__y-y-q {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative
}

.Modal-module_container__y-y-q .Modal-module_content__6K7Qz {
    position: relative;
    flex: 2 1;
    overflow-y: auto
}

.Modal-module_header__ZTPU1 {
    position: relative;
    display: flex;
    justify-content: flex-start;
    background-color: var(--color-gray-100);
    padding: 22px 0 21px 16px;
    font-size: 16px;
    font-weight: 500
}

.Modal-module_header__ZTPU1 .Modal-module_title__VzBlD {
    flex: 2 1;
    text-align: center;
    align-self: center
}

.Modal-module_header__ZTPU1 .Modal-module_btnIconClose__BWNN- {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer
}

.Modal-module_noTitleHeader__y0yC6 {
    position: relative;
    display: flex;
    justify-content: flex-start
}

.Modal-module_noTitleHeader__y0yC6 .Modal-module_btnIconClose__BWNN- {
    position: absolute;
    right: 8px;
    transform: translateY(35%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 1
}

.Modal-module_closeContainer__vfcLM {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 50%
}

@media (min-width: 768px) {
    .Modal-module_overlay__jQMv2 {
        display:flex;
        justify-content: center;
        align-items: center
    }

    .Modal-module_noTitleModal__5E5uL {
        margin-top: 10vh
    }

    .Modal-module_modal__7sLa5,.Modal-module_noTitleModal__5E5uL {
        width: -moz-fit-content;
        width: fit-content;
        min-width: 400px;
        max-width: 900px;
        border-radius: 16px;
        margin-top: 0
    }

    .Modal-module_modal__7sLa5.Modal-module_full__0O-Ci,noTitleModal.Modal-module_full__0O-Ci {
        width: 90vw
    }

    .Modal-module_overlay__jQMv2.Modal-module_right__Nf3pD {
        align-items: flex-start;
        justify-content: flex-end
    }

    .Modal-module_overlay__jQMv2.Modal-module_right__Nf3pD .Modal-module_modal__7sLa5,.Modal-module_overlay__jQMv2.Modal-module_right__Nf3pD .Modal-module_noTitleModal__5E5uL {
        margin-top: 0;
        border-radius: 0;
        height: 100%;
        width: 490px;
        transform: translateX(100%)
    }

    .Modal-module_overlay__jQMv2.Modal-module_right__Nf3pD.Modal-module_isVisible__NrC08 .Modal-module_modal__7sLa5,.Modal-module_overlay__jQMv2.Modal-module_right__Nf3pD.Modal-module_isVisible__NrC08 .Modal-module_noTitleModal__5E5uL {
        transform: translateX(0)
    }
}

.Medialist-module_overallContainer__cGrOJ {
    display: flex;
    flex-direction: column
}

.Medialist-module_buttonBar__ugS16 {
    display: none
}

@media (hover: hover) {
    .Medialist-module_buttonBar__ugS16 {
        display:flex;
        flex-direction: row;
        justify-content: flex-end
    }
}

.Medialist-module_buttonSpacer__ubE9v {
    display: flex;
    width: 10px
}

.Medialist-module_backNextButtonActive__mJf8v {
    background-color: var(--color-blue-500)
}

.Medialist-module_backNextButtonActive__mJf8v,.Medialist-module_backNextButtonInactive__GFzG5 {
    display: flex;
    margin: 0;
    border: 0;
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer
}

.Medialist-module_backNextButtonInactive__GFzG5 {
    background-color: var(--color-gray-600)
}

.Medialist-module_backArrow__wBnAF {
    fill: var(--color-white);
    height: 14px;
    width: 14px;
    margin-left: -2px;
    transform: rotate(90deg)
}

.Medialist-module_nextArrow__ihTIk {
    fill: var(--color-white);
    height: 14px;
    width: 14px;
    margin-left: 2px;
    transform: rotate(270deg)
}

.Medialist-module_largeItem__YyBLe {
    padding: 15px 0;
    margin-bottom: 0;
    scroll-snap-align: start;
    border-bottom: 1px solid rgba(0,0,0,.1)
}

.Medialist-module_largeItemMobile__zmp8Y {
    display: flex
}

@media (min-width: 768px) {
    .Medialist-module_overallContainer__cGrOJ {
        flex-direction:row;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        overscroll-behavior-x: none
    }

    .Medialist-module_largeItem__YyBLe {
        margin-right: 20px;
        border-bottom: none
    }

    .Medialist-module_overflowXVisible__7PXUA {
        overflow-x: visible!important
    }

    .Medialist-module_largeItemMobile__zmp8Y {
        display: none
    }

    .Medialist-module_largeItemDesktop__ykJrz {
        display: flex
    }
}

.MedialistInner-module_container__LBzLk {
    display: grid;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 20px;
    padding-right: 10px;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: mandatory;
    overscroll-behavior-x: none;
    overflow-x: scroll
}

.MedialistInner-module_gradient__ttBrN {
    mask: linear-gradient(90deg,transparent 0,var(--color-blue-300),15px,var(--color-blue-300) calc(100% - 15px),transparent 100%);
    -webkit-mask: linear-gradient(90deg,transparent 0,var(--color-blue-300),15px,var(--color-blue-300) calc(100% - 15px),transparent 100%)
}

.MedialistInner-module_child__P5BSj {
    scroll-snap-align: start;
    padding-left: 15px;
    margin-right: -15px
}

@media (max-width: 650px) {
    .MedialistInner-module_container__LBzLk {
        grid-template-columns:none;
        margin-left: -10px;
        padding-right: 20px
    }

    .MedialistInner-module_gradient__ttBrN {
        -webkit-mask: none;
        mask: none
    }

    .MedialistInner-module_child__P5BSj {
        padding-left: 10px;
        margin-right: -10px
    }
}

@media (min-width: 768px) {
    .MedialistInner-module_overflowXVisible__mcwB- {
        overflow-x:visible
    }
}

.PlayableTrackButton-module_playButton__FgEST,.PlayableTrackButton-module_playButton__FgEST:hover {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-width: 0;
    border-radius: 50%;
    justify-content: center;
    align-items: center
}

.PlayableTrackButton-module_playButton__FgEST:hover {
    cursor: pointer
}

.PlayableTrackButton-module_circleShadow__2RQwv {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: var(--color-black);
    opacity: 70%
}

.PlayableTrackButton-module_circleProgress__ldZf2 {
    display: flex;
    position: absolute;
    width: 96%;
    height: 96%;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    -webkit-mask: radial-gradient(transparent 62%,0,var(--color-white));
    mask: radial-gradient(transparent 62%,0,var(--color-white));
    opacity: 0;
    animation: PlayableTrackButton-module_fadeIn__i49-l .5s forwards
}

@keyframes PlayableTrackButton-module_fadeIn__i49-l {
    to {
        opacity: 1
    }
}

.PlayableTrackButton-module_controlIconHidden__Wvryf {
    opacity: 0;
    transform: scale(.5)
}

.PlayableTrackButton-module_controlIconHidden__Wvryf,.PlayableTrackButton-module_controlIconShowing__bN2qN {
    display: flex;
    position: absolute;
    width: 35%;
    height: 35%;
    fill: var(--color-white);
    transition: opacity .3s,transform .3s
}

.PlayableTrackButton-module_controlIconShowing__bN2qN {
    opacity: 1;
    transform: scale(1)
}

@keyframes PlayableTrackButton-module_spin__-FwVW {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

.PlayableTrackButton-module_controlIconShowingBigSpinning__SnaIr {
    display: flex;
    position: absolute;
    width: 90%;
    height: 90%;
    fill: var(--color-white);
    animation: PlayableTrackButton-module_spin__-FwVW 2s linear infinite
}

.common-module_pointerEventsAuto__ccK-O {
    pointer-events: auto
}

.common-module_link__427Ok {
    text-decoration: none;
    color: inherit
}

.common-module_link__427Ok:hover {
    text-decoration: underline;
    text-decoration-thickness: 1.5px
}

.SongItem-module_container__XW6-b {
    height: 75px;
    width: 100%;
    padding: 20px 0;
    border-radius: 10px;
    transition: background-color .3s
}

.SongItem-module_container__XW6-b:hover {
    background-color: var(--color-gray-300)
}

.SongItem-module_container__XW6-b:after {
    content: "";
    position: absolute;
    left: 50px;
    right: 0;
    bottom: -1px;
    height: 1px;
    background-color: rgba(0,0,0,.1)
}

.SongItem-module_card__p7LL- {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    pointer-events: none
}

.SongItem-module_rankingNumber__3oDWK {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 75px;
    align-items: center;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    justify-content: center
}

.SongItem-module_thumbnailPlayButtonStack__kSR2v {
    display: flex;
    width: 75px;
    min-width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    background-color: var(--color-gray-300)
}

.SongItem-module_playButtonContainer__tR-gl {
    display: flex;
    position: absolute
}

.SongItem-module_linkColumn__k1QwT {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 70%
}

.SongItem-module_metadataLine__7Mm6B {
    display: flex;
    flex-direction: row;
    width: 50vw
}

.SongItem-module_mainItemsContainer__9MRor {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    justify-content: space-between;
    width: 100%;
    overflow: hidden
}

.SongItem-module_ellipisLink__DsCMc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    width: -moz-fit-content;
    width: fit-content
}

.SongItem-module_ellipisLink__DsCMc:hover {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.SongItem-module_explicit__Ali-N {
    display: flex;
    width: 14px;
    min-width: 14px;
    height: 14px;
    border-radius: 4.5px;
    background-color: var(--color-red-200);
    fill: var(--color-white);
    padding: 1px;
    margin-left: 7px;
    margin-top: 2px
}

.SongItem-module_badge__zn3ud {
    display: flex;
    width: 73px;
    min-width: 73px;
    margin-right: 15px;
    height: 24px;
    background-color: var(--color-black);
    border-radius: 24px;
    align-self: center;
    cursor: pointer;
    opacity: 1
}

.SongItem-module_appleMusicIcon__vR7Io {
    margin-top: -13px;
    margin-left: 11px;
    width: 50px;
    height: 50px;
    fill: var(--color-white)
}

.SongItem-module_cardPadding__wsrx8 {
    padding-left: 15px
}

.SongItem-module_mobileBottomGroup__OSsbY {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    left: 0;
    bottom: 0
}

.SongItem-module_badgeWrapper__PsGDI {
    padding-left: 100px
}

.SongItem-module_extraPadding__DrC-U {
    padding-left: 125px
}

.SongItem-module_contextIcon__viGJI {
    display: flex;
    align-items: center;
    opacity: 1;
    pointer-events: none
}

.SongItem-module_contextIcon__viGJI>span {
    pointer-events: auto
}

@media (min-width: 1024px) {
    .SongItem-module_mainItemsContainer__9MRor {
        display:flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        flex-grow: 1
    }

    .SongItem-module_linkColumn__k1QwT {
        display: flex;
        flex-direction: column;
        min-width: 0;
        justify-content: space-around;
        padding: 10px;
        width: auto
    }

    .SongItem-module_metadataLine__7Mm6B {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px
    }

    .SongItem-module_badge__zn3ud {
        display: flex;
        width: 73px;
        min-width: 73px;
        margin-right: 0;
        height: 24px;
        background-color: var(--color-black);
        border-radius: 24px;
        align-self: center;
        cursor: pointer;
        opacity: 1
    }

    .SongItem-module_mobileBottomGroup__OSsbY {
        all: revert;
        display: flex;
        position: static
    }

    .SongItem-module_badgeWrapper__PsGDI {
        padding-left: 0;
        display: flex;
        align-items: center
    }

    @media (hover: hover) {
        .SongItem-module_contextIcon__viGJI {
            opacity:0
        }

        .SongItem-module_container__XW6-b:hover .SongItem-module_contextIcon__viGJI {
            opacity: 1;
            transition: all .25s ease-in-out;
            margin-left: auto
        }

        .SongItem-module_container__XW6-b:hover .SongItem-module_linkColumn__k1QwT {
            max-width: 70%
        }

        .SongItem-module_container__XW6-b .SongItem-module_badge__zn3ud {
            opacity: 0
        }

        .SongItem-module_container__XW6-b:hover .SongItem-module_badge__zn3ud {
            opacity: 1;
            transition: all .25s ease-in-out
        }
    }
}

@media (min-width: 768px) {
    .SongItem-module_container__XW6-b {
        width:100%;
        padding: 15px 0
    }

    .SongItem-module_container__XW6-b:hover {
        background-color: var(--color-gray-300)
    }

    .SongItem-module_metadataLine__7Mm6B {
        width: 100%
    }
}

.ShareItem-module_btn__mKFIN {
    padding: 16px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-400);
    letter-spacing: .5px;
    text-transform: uppercase
}

.ShareItem-module_standard__KdZVw {
    border-radius: 8px
}

.ShareItem-module_pill__XH5ou {
    border-radius: 50px
}

.ShareItem-module_share__9BOG7 {
    margin-right: 5px
}

.ArtistShowcase-module_container__Wx2Ja {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 30px;
    margin-bottom: 30px
}

.ArtistShowcase-module_item__pACIZ {
    display: block
}

.ArtistShowcase-module_item__pACIZ img {
    display: block;
    border-radius: 50%;
    border: 3px solid #fff;
    width: 119px;
    height: 119px;
    -o-object-fit: cover;
    object-fit: cover
}

.ArtistShowcase-module_item__pACIZ:first-of-type {
    position: relative;
    left: 40px
}

.ArtistShowcase-module_item__pACIZ:nth-of-type(3) {
    position: relative;
    right: 40px
}

.ArtistShowcase-module_item__pACIZ:nth-of-type(2) img {
    position: relative;
    z-index: 2;
    width: 160px;
    height: 160px;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,.3)
}

[data-theme=dark] .ArtistShowcase-module_item__pACIZ img {
    border: 3px solid var(--color-gray-900)
}

[data-theme=fun] .ArtistShowcase-module_item__pACIZ img {
    border: 3px solid var(--color-yellow-100);
    filter: brightness(1.3) invert(.17) saturate(2.6) sepia(.25)
}

.TitleSection-module_backgroundColor__BbiGB {
    background-color: var(--color-gray-200)
}

.TitleSection-module_title__YSbpN {
    padding: 25px 0 15px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: flex-end;
    letter-spacing: .5px
}

.TitleSection-module_titleWrapper__VmxpP {
    margin-bottom: 10px
}

.TitleSection-module_titleDivider__PyylE {
    padding: 25px 0 12px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e4e4e4
}

.TitleSection-module_wrapper__26PBc {
    max-width: var(--page-max-width);
    margin: 0 auto
}

.TitleSection-module_marginBottom__lGE-J {
    margin-bottom: 40px
}

.TitleSection-module_actionContainer__vLwN5 {
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap
}

.TitleSection-module_actionContainer__vLwN5 .TitleSection-module_iconArrow__YSFnw {
    width: 10px!important;
    height: 12px!important;
    fill: var(--color-blue-500)!important;
    rotate: 270deg!important
}

.TrackFloatingMenu-module_anchorButton__Yv5xf {
    display: flex;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
    align-self: center;
    cursor: pointer
}

.TrackFloatingMenu-module_floatingPortal__hJzx- {
    z-index: 100
}

.TrackFloatingMenu-module_floatingPortalMobile__6ELBs {
    z-index: 10000;
    width: 100%;
    position: fixed!important;
    top: auto!important;
    bottom: 0;
    transform: none!important;
    transition: transform .8s cubic-bezier(.4,.2,0,1)
}

.TrackFloatingMenu-module_modalContain__-dqFy {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 10px;
    border-style: 1px;
    border-width: 1px;
    filter: drop-shadow(0 0 10px var(--color-gray-600));
    z-index: 10;
    overflow: hidden
}

.TrackFloatingMenu-module_modalContainMobile__gaE2w {
    border-radius: 0;
    filter: none
}

.TrackFloatingMenu-module_headerInfo__WYr35 {
    display: flex;
    flex-direction: row;
    width: 288px;
    padding: 16px
}

.TrackFloatingMenu-module_title__uobtn {
    width: 210px;
    margin-bottom: 4px
}

.TrackFloatingMenu-module_subtitle__iwp8Q {
    width: 210px;
    color: var(--color-gray-900)
}

.TrackFloatingMenu-module_contextualData__kwxA7 {
    display: flex;
    align-items: center;
    color: var(--color-gray-900);
    margin-top: 6px
}

.TrackFloatingMenu-module_shazamIcon__rkpAu {
    display: flex;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: var(--color-gray-800)
}

.TrackFloatingMenu-module_description__OlZel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px
}

.TrackFloatingMenu-module_thumbnail__N6DdF {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    background-color: var(--color-gray-300)
}

.TrackFloatingMenu-module_linkBox__AC5bA {
    display: flex;
    width: 100%;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 0;
    margin-right: 0;
    border: 0;
    border-top: 1px;
    border-style: solid;
    border-color: var(--color-gray-600);
    align-items: center;
    fill: var(--color-gray-600);
    background-color: transparent
}

.TrackFloatingMenu-module_linkBox__AC5bA:hover {
    fill: var(--color-blue-300);
    cursor: pointer;
    background-color: var(--color-gray-600)
}

.TrackFloatingMenu-module_shareButton__bBzn- {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border-width: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%
}

.TrackFloatingMenu-module_menuIcon__r00Qy {
    width: 20px;
    height: 20px;
    fill: inherit
}

.TrackFloatingMenu-module_menuText__1tljj {
    display: flex;
    margin-left: 15px
}

.TrackFloatingMenu-module_iconAppleMusic__cR4-u {
    fill: var(--color-black);
    height: 55px;
    width: 55px;
    margin-left: 5px
}

.TrackFloatingMenu-module_overlay__dsNgj {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: var(--color-black);
    opacity: .9;
    width: 100%;
    height: 100%
}

.ShareFloatingMenu-module_anchorButton__Sgxc7 {
    display: flex;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
    align-self: center;
    cursor: pointer
}

.ShareFloatingMenu-module_floatingPortal__HHxUB {
    z-index: 100
}

.ShareFloatingMenu-module_modalContain__vcHXp {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 10px;
    border-style: 1px;
    border-width: 1px;
    filter: drop-shadow(0 0 10px var(--color-gray-600));
    z-index: 10;
    overflow: hidden
}

.ShareFloatingMenu-module_headerInfo__ZOjY4 {
    display: flex;
    flex-direction: row;
    width: 288px;
    padding: 16px
}

.ShareFloatingMenu-module_title__W--DE {
    width: 210px;
    margin-bottom: 4px
}

.ShareFloatingMenu-module_subtitle__lI5qI {
    width: 210px;
    color: var(--color-gray-900)
}

.ShareFloatingMenu-module_contextualData__rguDA {
    display: flex;
    align-items: center;
    color: var(--color-gray-900);
    margin-top: 6px
}

.ShareFloatingMenu-module_shazamIcon__2cxiJ {
    display: flex;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: var(--color-gray-800)
}

.ShareFloatingMenu-module_description__Xm4Ua {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px
}

.ShareFloatingMenu-module_thumbnail__puLFF {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    background-color: var(--color-gray-300)
}

.ShareFloatingMenu-module_circleThumbnail__r0v-G {
    width: 60px;
    height: 60px;
    border-radius: 30px
}

.ConcertEventItem-module_container__QGjH1 {
    border-radius: 10px;
    background-color: var(--color-white);
    transition: background-color .3s
}

.ConcertEventItem-module_container__QGjH1:hover {
    background-color: var(--color-gray-300)
}

.ConcertEventItem-module_listItem__KujvK {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none
}

.ConcertEventItem-module_event__-mRCF {
    display: grid;
    grid-gap: 0 15px;
    gap: 0 15px;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto auto auto auto 1fr;
    padding: 16px 0 16px 16px;
    color: #000;
    text-decoration: none;
    pointer-events: none;
    position: relative;
    z-index: 1
}

.ConcertEventItem-module_event__-mRCF:hover,.ConcertEventItem-module_event__-mRCF:visited {
    color: #000;
    text-decoration: none
}

.ConcertEventItem-module_event__-mRCF:hover {
    border-radius: 8px;
    background-color: rgba(0,136,255,.03)
}

.ConcertEventItem-module_event__-mRCF:after {
    content: "";
    position: absolute;
    left: 135px;
    right: 15px;
    bottom: 0;
    background-color: #efefef;
    height: 1px
}

.ConcertEventItem-module_listItem__KujvK.ConcertEventItem-module_listItemAlternate__mZacH .ConcertEventItem-module_event__-mRCF:after {
    content: none
}

.ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_art__j8KIY {
    position: relative;
    grid-row-start: 1;
    grid-row-end: 6;
    border: 1px solid rgba(0,0,0,.1);
    background-color: #e5e5ea;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px
}

.ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_art__j8KIY .ConcertEventItem-module_img__owjmM {
    border-radius: 4px
}

.ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_dateText__IA2dc {
    margin-left: 5px
}

.ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_date__qkznc {
    margin-bottom: 6px;
    display: flex;
    align-items: center
}

.ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_title__kcxZP {
    margin-bottom: 4px
}

.ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_venue__2hNo- {
    margin-bottom: 5px;
    padding-right: 30px
}

.ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_location__krex5 {
    font-size: 12px;
    opacity: .5
}

.ConcertEventItem-module_topSongsButton__lhfS3 {
    position: absolute;
    z-index: 10;
    left: 24px;
    bottom: 24px
}

.ConcertEventItem-module_listItem__KujvK.ConcertEventItem-module_listItemAlternate__mZacH .ConcertEventItem-module_topSongsButton__lhfS3 {
    left: 24px
}

.ConcertEventItem-module_contextIcon__hN66K {
    position: absolute;
    z-index: 11;
    right: 12px;
    bottom: 24px
}

.ConcertEventItem-module_isLoading__s6qji .ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_art__j8KIY {
    background-color: #efefef
}

.ConcertEventItem-module_thumbnailPlayButtonStack__IWhfo {
    display: flex;
    width: 75px;
    min-width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    background-color: var(--color-gray-300)
}

.ConcertEventItem-module_isLoading__s6qji .ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_date__qkznc,.ConcertEventItem-module_isLoading__s6qji .ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_location__krex5,.ConcertEventItem-module_isLoading__s6qji .ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_title__kcxZP,.ConcertEventItem-module_isLoading__s6qji .ConcertEventItem-module_event__-mRCF .ConcertEventItem-module_venue__2hNo- {
    max-width: 200px;
    background-color: #efefef;
    height: 15px;
    border-radius: 10px;
    margin-bottom: 10px
}

.ConcertEventItem-module_noEvents__opiN6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 0 20px;
    margin: 10px auto 0;
    width: 100%;
    max-width: 650px;
    border-top: 1px solid var(--color-gray-400)
}

.ConcertEventItem-module_noEvents__opiN6 .ConcertEventItem-module_avatarWrapper__6CScT {
    position: relative
}

.ConcertEventItem-module_noEvents__opiN6 .ConcertEventItem-module_avatar__kJalu {
    position: absolute;
    bottom: 15px;
    transform: translateX(-50%)
}

.ConcertEventItem-module_noEvents__opiN6 h2 {
    margin-bottom: 5px
}

.ConcertEventItem-module_badgeContainer__07iHu {
    margin-top: 19px;
    display: flex;
    align-items: center;
    gap: 4px
}

.ConcertEventItem-module_genreBadge__Xk4Pq {
    border: 1px solid var(--color-black);
    border-radius: 8px
}

@media (min-width: 600px) {
    .ConcertEventItem-module_event__-mRCF {
        padding:20px 0 20px 20px
    }
}

@media (hover: hover) {
    .ConcertEventItem-module_contextIcon__hN66K {
        opacity:0
    }

    .ConcertEventItem-module_container__QGjH1:hover .ConcertEventItem-module_contextIcon__hN66K {
        opacity: 1
    }
}

.FloatingMessage-module_msg__MuHNk {
    border-radius: 4px;
    background-color: var(--color-blue-400);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    padding: 8px 16px
}

.FeaturedVideo-module_shz-frame-event-behindtheshow__9VKC- .FeaturedVideo-module_item-container__ypT-T {
    background-color: #141414;
    border-radius: 15px
}

.FeaturedVideo-module_hide__K0pr9 {
    display: none
}

.FeaturedVideo-module_show__6xc-G {
    display: block
}

.FeaturedVideo-module_videoContainer__S-Ajv {
    position: relative;
    z-index: 20;
    width: 500px
}

.FeaturedVideo-module_videoContainer__S-Ajv .FeaturedVideo-module_playButton__SxBxw.FeaturedVideo-module_hide__K0pr9 {
    opacity: 0
}

.FeaturedVideo-module_videoContainer__S-Ajv .FeaturedVideo-module_info__rJLLk {
    position: absolute;
    padding: 15px 16px;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 2;
    transition: opacity .5s ease;
    opacity: 1;
    pointer-events: none;
    box-sizing: border-box
}

.FeaturedVideo-module_title__Z4fP- {
    color: #fff;
    width: 100%;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: .5px;
    pointer-events: none
}

.FeaturedVideo-module_videoContainer__S-Ajv .FeaturedVideo-module_playButton__SxBxw {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    opacity: 1;
    transition: opacity .5s ease;
    pointer-events: none;
    visibility: visible
}

.FeaturedVideo-module_videoWrapper__7Xaiw {
    height: 280px;
    width: 500px;
    z-index: 1;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
    cursor: pointer
}

.FeaturedVideo-module_videoWrapper__7Xaiw .FeaturedVideo-module_poster__8O7dd {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 1;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;
    z-index: 2
}

.FeaturedVideo-module_videoWrapper__7Xaiw .FeaturedVideo-module_videoGradient__mMRFB {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    z-index: 3;
    pointer-events: none;
    background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.8));
    opacity: 1;
    will-change: opacity;
    transition: opacity .3s ease-out
}

.FeaturedVideo-module_videoWrapper__7Xaiw video {
    height: 100%;
    width: 100%;
    border: 0;
    z-index: 1;
    opacity: 0;
    -o-object-fit: contain;
    object-fit: contain
}

.FeaturedVideo-module_videoWrapper__7Xaiw video.FeaturedVideo-module_playing__5-lVs {
    opacity: 1
}

.FeaturedVideo-module_videoWrapper__7Xaiw video.FeaturedVideo-module_playing__5-lVs+.FeaturedVideo-module_poster__8O7dd {
    opacity: 0
}

.FeaturedVideo-module_videoWrapper__7Xaiw video.FeaturedVideo-module_playing__5-lVs~.FeaturedVideo-module_videoGradient__mMRFB {
    opacity: .4
}

@media (max-width: 768px) {
    .FeaturedVideo-module_videoWrapper__7Xaiw {
        height:170px;
        width: 300px;
        border-radius: 10px
    }

    .FeaturedVideo-module_videoContainer__S-Ajv {
        width: 300px
    }
}

@media (min-width: 768px) {
    .FeaturedVideo-module_videoContainer__S-Ajv .FeaturedVideo-module_info__rJLLk {
        padding:20px
    }

    .FeaturedVideo-module_videoWrapper__7Xaiw .FeaturedVideo-module_videoGradient__mMRFB {
        height: 80px
    }

    .FeaturedVideo-module_title__Z4fP- {
        font-size: 15px
    }
}

.VideoModal-module_videoContainer__6ppYm {
    display: inline-block;
    margin: 0 auto;
    max-width: 90vw;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 15px;
    background-color: #000
}

.VideoModal-module_videoContainer__6ppYm .VideoModal-module_videoWrapper__DbGVZ video {
    width: 100%;
    height: 100%;
    max-height: 90vh;
    -o-object-fit: contain;
    object-fit: contain
}

.VideoModal-module_close__RyQEd {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    z-index: 1000;
    cursor: pointer
}

.VideoModal-module_close__RyQEd svg {
    width: 30px;
    height: 30px;
    z-index: 1000
}

.VideoModal-module_close__RyQEd div svg {
    fill: #fff;
    opacity: .7;
    transition: opacity .1s linear
}

.VideoModal-module_close__RyQEd div:hover svg {
    opacity: 1
}

@media (max-width: 650px) {
    .VideoModal-module_close__RyQEd svg {
        width:20px;
        height: 20px
    }

    .VideoModal-module_close__RyQEd {
        padding: 10px
    }
}

.VideoModal-module_overlay__VNz2m {
    background: rgba(0,0,0,.9);
    display: grid;
    place-items: center;
    z-index: 5000
}

.LatestReleaseCard-module_latestReleaseCard__VaR8l {
    padding: 15px;
    margin: 0 0 30px;
    border-radius: 8px;
    box-sizing: border-box;
    height: 94%
}

.LatestReleaseCard-module_grid__toEpV {
    display: flex;
    max-width: 1340px
}

.LatestReleaseCard-module_art__I0e8y {
    border: 1px solid hsla(0,0%,100%,.1);
    border-radius: 8px;
    height: -moz-fit-content;
    height: fit-content
}

.LatestReleaseCard-module_releaseText__aajEL {
    margin: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.LatestReleaseCard-module_year__9-fcg {
    margin: 4px 0 10px
}

.LatestReleaseCard-module_badge__kOnLm {
    display: flex;
    width: 73px;
    min-width: 73px;
    margin-right: 15px;
    height: 24px;
    background-color: hsla(0,0%,100%,.15);
    border-radius: 24px;
    align-self: center;
    cursor: pointer;
    opacity: 1
}

.LatestReleaseCard-module_appleMusicIcon__sDszR {
    margin-top: -13px;
    margin-left: 11px;
    width: 50px;
    height: 50px;
    fill: var(--color-white)
}

.LatestReleaseCard-module_title__EneHY {
    margin: 0 0 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden
}

@media (min-width: 768px) {
    .LatestReleaseCard-module_latestReleaseCard__VaR8l {
        padding:20px
    }

    .LatestReleaseCard-module_releaseText__aajEL {
        width: 200px
    }

    .LatestReleaseCard-module_playButton__yD26I {
        margin-bottom: 13px
    }

    .LatestReleaseCard-module_title__EneHY {
        -webkit-line-clamp: 2
    }
}

.DropdownMenu-module_dropdownMenu__h038v {
    z-index: 101;
    min-width: 270px;
    border-radius: 8px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.3);
    border: 1px solid var(--color-gray-600);
    background-color: var(--color-white);
    color: var(--color-black-100);
    font-weight: 500;
    box-sizing: border-box;
    outline: 0;
    padding-top: 3px
}

.DropdownMenu-module_menuItems__salYd {
    position: relative;
    z-index: 11;
    max-height: 325px;
    overflow: auto;
    padding: 15px 15px 10px;
    margin-bottom: 5px
}

.DropdownMenu-module_arrow__sKjRJ {
    z-index: 10
}

.DropdownMenu-module_searchIcon__IMYYk {
    position: absolute;
    top: 50%;
    right: 10px;
    background: none;
    border: none;
    outline-offset: 2px;
    transform: translateY(-50%)
}

.DropdownMenu-module_searchInput__BvKkY {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .5px;
    outline: 0;
    background-color: transparent;
    padding: 10px 34px 10px 20px;
    border-radius: 8px;
    border: 1px solid var(--color-gray-600);
    width: 100%;
    color: var(--color-black-100);
    box-sizing: border-box
}

.DropdownMenu-module_searchForm__ievsp {
    position: relative;
    margin: 15px 15px 0
}

.DropdownMenu-module_searchInput__BvKkY::-moz-placeholder {
    font-size: 16px;
    color: var(--color-gray-700)
}

.DropdownMenu-module_searchInput__BvKkY::placeholder {
    font-size: 16px;
    color: var(--color-gray-700)
}

.PageGrid-module_container__Gqo-z {
    position: relative
}

.PageGrid-module_containerFullViewportHeight__KxhOt {
    min-height: 100vh
}

.PageGrid-module_grid__SjmKd {
    width: 95%;
    margin: 0 auto
}

.PageGrid-module_content__Ek7uQ,.PageGrid-module_grid__SjmKd.PageGrid-module_fullWidth__c9UaO {
    width: 100%
}

.PageGrid-module_content__Ek7uQ .PageGrid-module_inner__Ep8ld {
    width: 100%;
    margin: 0 auto
}

.PageGrid-module_grid__SjmKd.PageGrid-module_fullWidth__c9UaO .PageGrid-module_content__Ek7uQ {
    width: 100%
}

.PageGrid-module_grid__SjmKd.PageGrid-module_fullWidth__c9UaO .PageGrid-module_content__Ek7uQ .PageGrid-module_inner__Ep8ld {
    width: 95%
}

.PageGrid-module_navigation__99Mwm {
    position: absolute;
    width: 100%
}

.PageGrid-module_header__hLsmw {
    grid-area: header
}

.PageGrid-module_tabs__zH3Jg {
    grid-area: tabs;
    width: 95%;
    margin: 0 auto
}

.PageGrid-module_main__G-TaJ {
    grid-area: main
}

.PageGrid-module_sidebar__pjQ3F {
    grid-area: sidebar
}

.PageGrid-module_grid__SjmKd.PageGrid-module_activeTab__S8dW1 .PageGrid-module_sidebar__pjQ3F {
    width: 100%
}

.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd {
    display: grid;
    overflow: hidden
}

.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd.PageGrid-module_withSidebar__t8-8a {
    grid-template-columns: 100% 100%;
    grid-template-rows: auto;
    grid-template-areas: "main sidebar"
}

.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd.PageGrid-module_withSidebar__t8-8a.PageGrid-module_left__g4LsS {
    grid-template-areas: "sidebar main"
}

.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd .PageGrid-module_main__G-TaJ,.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd .PageGrid-module_sidebar__pjQ3F {
    min-width: 100%
}

.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd>div {
    transition: transform .3s ease-out
}

.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd.PageGrid-module_left__g4LsS.PageGrid-module_activeTabMain__jfKhJ>div,.PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd.PageGrid-module_right__1113p.PageGrid-module_activeTabSidebar__AILqO>div {
    transform: translateX(-100%)
}

.PageGrid-module_backgroundColor__vO-Gw {
    background-color: var(--color-gray-200)
}

@media (min-width: 1024px) {
    .PageGrid-module_tabs__zH3Jg {
        display:none
    }

    .PageGrid-module_grid__SjmKd {
        display: grid;
        grid-gap: 0 0;
        gap: 0 0;
        max-width: var(--page-max-width);
        margin: 0 auto;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas: "main"
    }

    .PageGrid-module_grid__SjmKd.PageGrid-module_fullWidth__c9UaO:not(.PageGrid-module_withSidebar__t8-8a) {
        max-width: 100%
    }

    .PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd>div {
        transform: none!important
    }

    .PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd.PageGrid-module_withSidebar__t8-8a,.PageGrid-module_grid__SjmKd.PageGrid-module_withSidebar__t8-8a {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: "main main sidebar" "main main sidebar"
    }

    .PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd.PageGrid-module_withSidebar__t8-8a.PageGrid-module_left__g4LsS,.PageGrid-module_grid__SjmKd.PageGrid-module_withSidebar__t8-8a.PageGrid-module_left__g4LsS {
        grid-template-areas: "sidebar main main" "sidebar main main"
    }

    .PageGrid-module_grid__SjmKd.PageGrid-module_withActiveTab__NaKHd.PageGrid-module_withSidebar__t8-8a.PageGrid-module_right__1113p,.PageGrid-module_grid__SjmKd.PageGrid-module_withSidebar__t8-8a.PageGrid-module_right__1113p {
        grid-template-areas: "main main sidebar" "main main sidebar"
    }

    .PageGrid-module_navigation__99Mwm {
        position: fixaed;
        top: 0;
        left: 0;
        z-index: 9999
    }
}

@media (min-width: 1380px) {
    .PageGrid-module_grid__SjmKd,.PageGrid-module_grid__SjmKd.PageGrid-module_fullWidth__c9UaO.PageGrid-module_withSidebar__t8-8a .PageGrid-module_content__Ek7uQ .PageGrid-module_inner__Ep8ld {
        width:100%
    }
}

.Playerbar-module_playerBar__Cv81G {
    --thumb-color: var(--color-gray-700)
}

.Playerbar-module_invisibleButton__LPi8V {
    display: flex;
    margin: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    align-items: center
}

.Playerbar-module_bar__hwod8 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px
}

.Playerbar-module_infoSection__7opJ- {
    display: flex;
    flex-direction: row;
    padding-right: 5px
}

.Playerbar-module_badgeSection__7xmhK {
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1
}

.Playerbar-module_trackerSection__LaoLo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 536px;
    padding-left: 30px;
    padding-right: 30px;
    flex-shrink: 1
}

.Playerbar-module_trackerWrapper__1co4v {
    display: flex;
    background-color: transparent;
    align-items: center;
    width: 100%;
    height: 30px;
    cursor: pointer
}

.Playerbar-module_trackerRightGrowSection__l5KEo {
    display: flex;
    width: 0;
    flex-grow: 1
}

.Playerbar-module_controlsSection__hw-aH {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.Playerbar-module_volumeSection__V5aLG {
    display: flex;
    padding-left: 30px;
    padding-right: 10px
}

.Playerbar-module_compactBar__PWAm1 {
    display: flex;
    flex-direction: row;
    align-items: left;
    padding: 6px 16px 7px;
    margin-top: -10px
}

.Playerbar-module_compactInfoSection__zAGFX {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    flex-direction: row;
    align-items: left
}

.Playerbar-module_compactSpacedRow__zrEHn {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap
}

.Playerbar-module_compactSongLabels__Em6xx {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%
}

.Playerbar-module_compactBadgeSection__CiD9s {
    display: flex;
    flex-direction: row
}

.Playerbar-module_compactControlsSection__OPE2F {
    display: flex;
    width: 73px;
    padding-left: 13px;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.Playerbar-module_thumbnail__1YLy9 {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    align-self: center;
    background-color: var(--color-gray-600);
    border-radius: 8px
}

.Playerbar-module_songLabels__A-JfA {
    display: flex;
    flex-direction: column;
    width: 190px
}

.Playerbar-module_missingSong__-HJbh {
    padding-top: 16px;
    display: flex
}

.Playerbar-module_missingSong__-HJbh,.Playerbar-module_nowPreviewing__l9i6Z {
    cursor: default;
    white-space: nowrap;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.Playerbar-module_songTitle__l7kkh:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.Playerbar-module_artistName__DJezC {
    cursor: default;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.Playerbar-module_badge__Pxgas {
    display: flex;
    border: 0;
    padding: 0;
    margin: 0;
    width: 73px;
    height: 24px;
    background-color: var(--color-black);
    border-radius: 24px;
    align-self: center;
    cursor: pointer
}

.Playerbar-module_appleMusicIcon__pBX4W {
    margin-top: -13px;
    margin-left: 11px;
    width: 50px;
    height: 50px;
    fill: var(--color-white)
}

.Playerbar-module_currTime__pPWmv {
    min-width: 35px
}

.Playerbar-module_currTime__pPWmv,.Playerbar-module_maxTime__fRcda {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: default;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.Playerbar-module_controlIcons__H44-u {
    width: 120px
}

.Playerbar-module_compactControlIcons__JcoBB,.Playerbar-module_controlIcons__H44-u {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px
}

.Playerbar-module_compactControlIcons__JcoBB {
    width: 73px
}

.Playerbar-module_backPlayPauseNext__yasG6 {
    fill: #000;
    width: 18px;
    height: 18px;
    cursor: pointer
}

.Playerbar-module_disabledBackPlayPauseNext__uhJ-C {
    fill: var(--color-gray-700);
    width: 18px;
    height: 18px;
    cursor: default
}

.Playerbar-module_playpauseCircle__b95eN {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-500)
}

.Playerbar-module_volbarHolder__f-TyN {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    align-items: center;
    margin-top: -104px;
    margin-left: -12px;
    margin-right: -12px;
    width: 46px;
    height: 150px;
    border: 1px solid var(--color-gray-600);
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.1),0 0 2px 0 rgba(0,0,0,.1)
}

.Playerbar-module_volumeIcon__1dHgN {
    display: flex;
    width: 24px;
    height: 22px;
    max-width: 24px;
    max-height: 22px;
    cursor: pointer;
    fill: var(--color-black)
}

.Playerbar-module_ellipisLink__ELDNG {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.Playerbar-module_ellipisLink__ELDNG:hover {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.Playerbar-rangeinputs-module_tracker__PVPrC {
    display: flex;
    width: 100%;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    overflow-x: hidden
}

.Playerbar-rangeinputs-module_tracker__PVPrC:focus {
    outline: none
}

.Playerbar-rangeinputs-module_tracker__PVPrC::-webkit-slider-runnable-track {
    background: var(--track-background,--color-gray-400);
    height: 4px;
    border-radius: 2px
}

.Playerbar-rangeinputs-module_tracker__PVPrC::-moz-range-track {
    background: var(--track-background,--color-gray-400);
    height: 4px;
    border-radius: 2px
}

.Playerbar-rangeinputs-module_tracker__PVPrC::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -2.8px;
    margin-right: 0;
    border-right: 0;
    padding-right: 0;
    background-color: var(--thumb-color,--color-black);
    height: 10px;
    width: 10px;
    border-radius: 50%
}

.Playerbar-rangeinputs-module_tracker__PVPrC::-moz-range-thumb {
    border: none;
    background-color: var(--thumb-color,--color-black);
    height: 10px;
    width: 10px;
    border-radius: 50%
}

.Playerbar-rangeinputs-module_volbar__VMny5 {
    display: flex;
    transform: rotate(270deg);
    margin-top: 36px;
    margin-bottom: 36px;
    width: 86px;
    height: 44px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    overflow-x: hidden
}

.Playerbar-rangeinputs-module_volbar__VMny5:focus {
    outline: none
}

.Playerbar-rangeinputs-module_volbar__VMny5::-webkit-slider-runnable-track {
    background: var(--volume-track-background,var(--color-gray-400));
    height: 4px;
    border-radius: 2px
}

.Playerbar-rangeinputs-module_volbar__VMny5::-moz-range-track {
    background: var(--volume-track-background,var(--color-gray-400));
    height: 4px;
    border-radius: 2px
}

.Playerbar-rangeinputs-module_volbar__VMny5::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -2.8px;
    margin-right: 0;
    border-right: 0;
    padding-right: 0;
    background-color: var(--color-blue-300);
    height: 10px;
    width: 10px;
    border-radius: 50%
}

.Playerbar-rangeinputs-module_volbar__VMny5::-moz-range-thumb {
    border: none;
    background-color: var(--color-blue-300);
    height: 10px;
    width: 10px;
    border-radius: 50%
}

.InformationBar-module_informationBar__nB4bh {
    text-align: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 5px;
    pointer-events: none
}

.InformationBar-module_informationBar__nB4bh * {
    box-sizing: border-box
}

.InformationBar-module_closeIcon__9Kx1W {
    width: 100%;
    height: 100%
}

.InformationBar-module_pill__zVGbS {
    position: relative;
    border-radius: 4px;
    max-width: 90%;
    color: var(--color-white);
    padding: 10px 35px 10px 15px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    cursor: pointer;
    opacity: 1;
    transition: transform .3s cubic-bezier(.4,.2,0,1),opacity 10ms linear .4s;
    -webkit-transition: transform .3s cubic-bezier(.4,.2,0,1),opacity 10ms linear .4s;
    display: inline-block;
    transform: translateY(-150%);
    -webkit-transform: translateY(-150%);
    pointer-events: auto
}

.InformationBar-module_pill__zVGbS.InformationBar-module_inView__KnPs3 {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0)
}

.InformationBar-module_dismiss__h5ENL {
    position: absolute;
    padding: 11px;
    top: 0;
    right: 0;
    width: 30px;
    height: 35px
}

.InformationBar-module_dismiss__h5ENL svg {
    width: 100%;
    height: 100%;
    fill: var(--color-white)
}

.VideoFallback-module_container__tBOQD {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 30px 80px;
    overflow: hidden
}

.VideoFallback-module_attribution__qqxE1 {
    position: absolute;
    left: 20px;
    right: 175px;
    bottom: 20px;
    z-index: 10
}

.VideoFallback-module_imageWrapper__Bwp-A {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 125px
}

.VideoFallback-module_image__rbA92 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    box-shadow: 0 6px 12px 0 rgba(0,0,0,.1),0 10px 24px 0 rgba(0,0,0,.1)
}

.VideoFallback-module_wrapper__eTIEL {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    border-radius: 8px
}

.VideoFallback-module_wrapper__eTIEL:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60%;
    background-image: linear-gradient(0deg,rgba(0,0,0,.35),hsla(0,0%,100%,0))
}

.VideoFallback-module_trackButtonOverlay__ePPFP {
    position: absolute;
    z-index: 10;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 125px;
    border-radius: 8px
}

.VideoFallback-module_trackButtonOverlay__ePPFP .VideoFallback-module_trackButtonContainer__6IGdT {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
}

.Search-module_groupHeader__eND10 {
    display: grid;
    grid-template-columns: 1fr auto
}

.Search-module_groupHeader__eND10 .Search-module_clear__9x6G9 {
    cursor: pointer
}

.EventFeaturedHeader-module_container__DdZaE {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: var(--page-max-width);
    margin: 0 auto;
    overflow: hidden
}

.EventFeaturedHeader-module_container__DdZaE * {
    box-sizing: border-box
}

.EventFeaturedHeader-module_heroShare__vnOvI {
    display: none;
    position: absolute;
    right: 2.5%;
    padding: 10px;
    width: 50px;
    height: 50px;
    border: 0;
    border-radius: 50%;
    -webkit-backdrop-filter: blur(37px);
    backdrop-filter: blur(37px);
    background-color: hsla(0,0%,100%,.2)
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_subContainer__ZrnfX {
    margin-top: 50vw;
    z-index: 2
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_colorFade__oGCXB {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    opacity: 1;
    background-image: linear-gradient(0deg,#1c1c1c,rgba(28,28,28,0))
}

.EventFeaturedHeader-module_artworkBlur__uq0uu {
    height: 110%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: scale(1.2);
    opacity: .5;
    overflow: hidden
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artworkBlurMain__yfN1O {
    height: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artworkBlurMain__yfN1O img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artworkBlur__uq0uu:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: transparent
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artwork__-lZoR {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artwork__-lZoR>div {
    width: 100%;
    height: 100vw;
    -webkit-mask-image: -webkit-linear-gradient(top,#000,#000 60%,transparent)
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_logo__-MAC9>div {
    width: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_logo__-MAC9>div img {
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    width: 100%;
    height: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artwork__-lZoR>div img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    width: 100%;
    height: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyContainer__zXit- {
    padding: 25px 15px 15px;
    display: block;
    justify-content: center
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyContainerMobile__lEGS3 {
    padding: 25px 15px 15px;
    display: block;
    justify-content: center;
    height: 230px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyContainerMobile__lEGS3 .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7 {
    padding: 25px 15px 15px;
    max-width: 560px;
    display: grid;
    grid-template-rows: 1fr .4fr
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt {
    z-index: 1
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_logo__-MAC9 {
    display: flex;
    align-items: flex-end;
    width: 160px;
    margin-bottom: 10px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_title__Bf4Ur {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.28;
    letter-spacing: .5px;
    color: #fff;
    text-align: center;
    word-break: break-all;
    max-width: 100%;
    margin-bottom: 5px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_description__h4oRw {
    font-size: 14px;
    letter-spacing: .25px;
    color: #fff;
    margin: 0 0 10px;
    text-align: center;
    word-break: break-all;
    max-width: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_dateTime__LYnE1 {
    display: flex;
    gap: 10px;
    justify-content: center;
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: .4px;
    color: rgba(235,235,245,.6)
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_dateTime__LYnE1>div {
    display: flex;
    align-items: center
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_dateTime__LYnE1 .EventFeaturedHeader-module_icon__v1DCH {
    width: 12px;
    fill: rgba(235,235,245,.6);
    margin-right: 5px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt {
    width: 100%;
    padding: 20px;
    border-radius: 16px;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    border: .5px solid hsla(0,0%,100%,.1);
    background-color: rgba(0,0,0,.26);
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7 .EventFeaturedHeader-module_animBellIcon__sIOii,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_animBellIcon__sIOii {
    position: relative;
    top: 2px;
    width: 17px;
    margin-right: 5px;
    animation-duration: .82s;
    animation-delay: 3s;
    animation-iteration-count: 4;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    animation-name: EventFeaturedHeader-module_anim-bell-shake__LWKpj
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7 .EventFeaturedHeader-module_title__Bf4Ur,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_title__Bf4Ur {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: .5px;
    color: #fff;
    margin-bottom: 10px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7 .EventFeaturedHeader-module_description__h4oRw,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_description__h4oRw {
    margin-bottom: 23px;
    font-size: 14px;
    line-height: 1.36;
    letter-spacing: .47px;
    color: #fff
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_btnNotify__uQSfb {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 15px;
    border-radius: 10px;
    background-color: #fa233b;
    width: 100%;
    border: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.31;
    letter-spacing: .3px;
    text-align: center;
    color: #fff;
    white-space: nowrap;
    text-decoration: none
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_btnNotify__uQSfb .EventFeaturedHeader-module_icon__v1DCH {
    width: 20px;
    margin-right: 8px;
    fill: #fff
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_qr__9p99o {
    display: block;
    min-width: 100px;
    max-width: 100px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_qr__9p99o>div {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    min-height: 100px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_qr__9p99o>div img {
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    width: 100%;
    height: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_qrCaption__Vdua2 {
    display: block;
    margin: 0 0 6px;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: .5px;
    color: rgba(235,235,245,.6)
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_liveBadge__r-5J4 {
    height: 20px;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: var(--color-red-400);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: .5px;
    color: #fff;
    text-transform: uppercase;
    min-width: 58px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_liveBadge__r-5J4 .EventFeaturedHeader-module_icon__v1DCH {
    fill: #fff;
    width: 8px;
    margin-right: 2px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_watchNowPanel__bqZY8 {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_watchNowPanel__bqZY8 button {
    max-width: 320px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_watchNowCaption__13qqy {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: .5px;
    color: rgba(235,235,245,.6);
    margin-top: 10px
}

.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_watchNowCaption__13qqy .EventFeaturedHeader-module_icon__v1DCH {
    width: 11px;
    margin-left: 4px;
    fill: rgba(235,235,245,.6);
    transform: translateY(2px)
}

@keyframes EventFeaturedHeader-module_anim-bell-shake__LWKpj {
    0%,to {
        transform: rotate(0deg)
    }

    20%,60% {
        transform: rotate(6deg)
    }

    40%,80% {
        transform: rotate(-6deg)
    }

    to {
        transform: rotate(0deg)
    }
}

@media (min-width: 768px) {
    .EventFeaturedHeader-module_container__DdZaE {
        position:relative;
        padding: 30px 2.5%
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artworkBlur__uq0uu {
        display: none
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artwork__-lZoR>div {
        border-radius: 15px;
        height: 100%;
        max-height: none;
        -webkit-mask-image: unset;
        mask-image: none;
        border-radius: 12px;
        overflow: hidden
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artwork__-lZoR {
        margin: 0
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_subContainer__ZrnfX {
        margin-top: 0;
        padding: 0 0 0 37px;
        display: flex;
        flex-direction: column;
        gap: 20px
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_logo__-MAC9 {
        width: 111px
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_title__Bf4Ur {
        letter-spacing: .39px
    }

    .EventFeaturedHeader-module_conctainer__8jFls .EventFeaturedHeader-module_notifyContainerMobile__lEGS3,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyContainer__zXit- {
        padding: 0 15px 0 0;
        display: block
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyContainer__zXit- .EventFeaturedHeader-module_notifyPanel__sVQlt {
        border-radius: 0;
        -webkit-backdrop-filter: 0;
        backdrop-filter: 0;
        border: 0;
        border-top: 1px solid hsla(0,0%,100%,.1);
        background-color: transparent;
        display: flex
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyContainerMobile__lEGS3 .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7 {
        border-radius: 0;
        -webkit-backdrop-filter: 0;
        backdrop-filter: 0;
        border: 0;
        border-top: 1px solid hsla(0,0%,100%,.1);
        background-color: transparent
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_qrCaption__Vdua2 {
        margin: 0
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_logo__-MAC9 {
        margin-bottom: 0
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_watchNowPanel__bqZY8 {
        align-items: flex-start;
        margin-bottom: 0
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_watchNowPanel__bqZY8 button {
        width: 100%
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_title__Bf4Ur {
        font-size: 22px;
        margin: 0 0 5px
    }

    .EventFeaturedHeader-module_containerWrapper__zAimm {
        background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0)),linear-gradient(180deg,#1c1c1e,#1c1c1e);
        padding-top: var(--page-navigation-height);
        height: 500px;
        padding-bottom: 40px;
        --info-height: 0
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0
    }

    .EventFeaturedHeader-module_container__DdZaE {
        flex-direction: row
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artwork__-lZoR {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 100%;
        max-width: 420px;
        max-height: 420px;
        font-size: 0
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b {
        align-items: flex-start
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_title__Bf4Ur {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: .44px;
        color: #fff;
        margin-bottom: 10px
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_description__h4oRw {
        font-size: 32px;
        line-height: 1.2;
        letter-spacing: .67px;
        color: #fff;
        margin-bottom: 15px
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_dateTime__LYnE1 {
        font-size: 12px;
        letter-spacing: .24px;
        color: #fff
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_dateTime__LYnE1 .EventFeaturedHeader-module_icon__v1DCH {
        fill: #fff
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_colorFade__oGCXB {
        display: none
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanelMobile__3b-Y7 .EventFeaturedHeader-module_description__h4oRw,.EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_description__h4oRw {
        color: #8e8e93;
        margin-bottom: 6px
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_notifyPanel__sVQlt .EventFeaturedHeader-module_qrCaption__Vdua2 {
        color: #8e8e93
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_watchNowPanel__bqZY8 {
        max-width: 320px
    }
}

@media (min-width: 1024px) {
    .EventFeaturedHeader-module_container__DdZaE {
        flex-direction:row;
        padding: 30px 2.5%
    }

    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_detail__Zmo5b .EventFeaturedHeader-module_description__h4oRw {
        text-align: left
    }
}

@media (min-width: 1536px) {
    .EventFeaturedHeader-module_container__DdZaE .EventFeaturedHeader-module_artwork__-lZoR {
        flex-direction:row
    }
}

.PromotionBanner-module_container__kX0z- {
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.PromotionBanner-module_container__kX0z- * {
    box-sizing: border-box
}

.PromotionBanner-module_container__kX0z- .PromotionBanner-module_backgroundImageContainer__xP04z {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    height: 100%;
    z-index: -1
}

.PromotionBanner-module_container__kX0z- .PromotionBanner-module_backgroundImageDynamic__B2Flo {
    height: 100%;
    background-color: rgba(0,0,0,.3)
}

.PromotionBanner-module_container__kX0z- .PromotionBanner-module_backgroundImageDynamic__B2Flo:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.3)
}

.PromotionBanner-module_container__kX0z- .PromotionBanner-module_header__xtryl {
    padding-bottom: 10px
}

@media (min-width: 768px) {
    .PromotionBanner-module_container__kX0z- {
        width:750px
    }
}

@media (min-width: 1024px) {
    .PromotionBanner-module_container__kX0z- {
        width:970px
    }
}

@media (min-width: 1280px) {
    .PromotionBanner-module_container__kX0z- {
        width:1170px
    }
}

.PromotionBanner-module_card__cBIFO {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo {
    width: 100%;
    --background-color: #ccc
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- {
    position: relative;
    overflow: hidden;
    width: 345px;
    height: 390px;
    margin: 0 auto;
    padding: 175px 25px 25px;
    border-radius: 20px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    background-color: var(--background-color);
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO {
    padding: 25px;
    height: auto;
    display: block;
    text-align: left;
    border-radius: 12px;
    border: none
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_title__eXAbI {
    font-weight: 700;
    font-size: 32px;
    letter-spacing: .32px;
    color: #fff;
    margin-bottom: 15px;
    max-width: 264px
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO .PromotionBanner-module_amLogoBackground__TB5oh {
    width: 40px;
    height: 40px;
    margin-bottom: 106px;
    display: flex;
    align-items: center;
    border-radius: 11px;
    background-image: linear-gradient(0deg,#fa233b,#fb5c74);
    box-shadow: 1px 2.5px 4px 2px rgba(0,0,0,.06)
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO .PromotionBanner-module_amLogoBackground__TB5oh .PromotionBanner-module_amLogo__PTsju {
    width: 27px!important;
    height: 26px!important;
    display: block;
    margin: 9px 5px 8px
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO .PromotionBanner-module_title__eXAbI {
    font-size: 26px;
    letter-spacing: .26px;
    font-weight: 700;
    margin-bottom: 56px;
    min-height: 93px
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO .PromotionBanner-module_muted__le7Be {
    color: hsla(0,0%,100%,.6)
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_title__eXAbI.PromotionBanner-module_webPromo__xN5A7 {
    font-size: 28px;
    max-width: 100%
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_subtitle__LdcHR {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: .56px;
    color: #fff;
    margin-bottom: 40px;
    max-width: 295px
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- button.PromotionBanner-module_close__b-EWV {
    position: absolute;
    right: 10px;
    top: 18px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-backdrop-filter: blur(22.1px);
    backdrop-filter: blur(22.1px);
    background-color: hsla(0,0%,100%,.15);
    display: flex;
    justify-content: center;
    align-items: center
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- button.PromotionBanner-module_close__b-EWV svg {
    display: block;
    height: 11px;
    width: 11px;
    fill: #fff
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- button.PromotionBanner-module_try__HDQfY {
    width: 100%;
    min-height: 45px;
    padding: 13px 0 14px;
    border-radius: 22.5px;
    border: none;
    background-color: #fff;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 700;
    color: var(--background-color);
    letter-spacing: .8px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- button.PromotionBanner-module_try__HDQfY.PromotionBanner-module_pressed__EFUud {
    transform: scale(.98)
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO button.PromotionBanner-module_try__HDQfY {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .29px;
    padding: 12px 15px
}

.PromotionBanner-module_shzPartialTop3__sHRcC .PromotionBanner-module_trackImage__OK9yg>div {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover
}

.PromotionBanner-module_fullWidth__nN6ZN {
    max-width: none!important;
    width: auto!important
}

@media (min-width: 768px) {
    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- {
        position:relative;
        overflow: hidden;
        width: 100%;
        max-width: 908px;
        height: 410px;
        margin: 0 auto;
        padding: 141px 35px 48px;
        border-radius: 20px;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
        background-color: var(--background-color);
        z-index: 0
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_top3Container__qoBlI .PromotionBanner-module_shzPartialTop3__sHRcC {
        --track-image-size: 182px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO {
        padding: 60px;
        border-radius: 16px;
        box-shadow: 0 2px 24px 0 rgba(0,0,0,.08)
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO .PromotionBanner-module_amLogoBackground__TB5oh {
        display: flex;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 11px;
        margin-bottom: 20px;
        background-image: linear-gradient(0deg,#fa233b,#fb5c74);
        box-shadow: 1px 2.5px 4px 2px rgba(0,0,0,.06)
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO .PromotionBanner-module_amLogoBackground__TB5oh .PromotionBanner-module_amLogo__PTsju {
        width: 35px!important;
        height: 34px!important;
        display: block;
        margin: 10px 12px 11px 9px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO .PromotionBanner-module_title__eXAbI {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: .5px;
        font-weight: 700;
        margin-bottom: 40px;
        min-height: 144px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_title__eXAbI {
        font-size: 50px;
        max-width: 468px;
        letter-spacing: .5px;
        margin-bottom: 15px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_title__eXAbI.PromotionBanner-module_languageEl__sjev- {
        font-size: 40px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_title__eXAbI.PromotionBanner-module_languageEs__EkeUM {
        font-size: 47px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- .PromotionBanner-module_subtitle__LdcHR {
        font-weight: 400;
        font-size: 20px;
        letter-spacing: .63px;
        color: #fff;
        margin-bottom: 35px;
        max-width: 655px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- button.PromotionBanner-module_try__HDQfY {
        min-width: 150px;
        width: -moz-fit-content;
        width: fit-content;
        height: 45px;
        padding: 12px 26px 13px;
        font-size: 16px
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z-.PromotionBanner-module_card__cBIFO button.PromotionBanner-module_try__HDQfY {
        width: -moz-fit-content;
        width: fit-content;
        min-width: 0;
        min-width: auto
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- button.PromotionBanner-module_close__b-EWV {
        right: 25px;
        top: 25px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        -webkit-backdrop-filter: blur(22.1px);
        backdrop-filter: blur(22.1px)
    }

    .PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_container__kX0z- button.PromotionBanner-module_close__b-EWV svg {
        height: 14px;
        width: 14px
    }
}

.PromotionBanner-module_shzFrameUxPlayerbarUpsellVariantB__jRIzo .PromotionBanner-module_variantB__ROJER {
    display: none
}

.PromotionBanner-module_authExpiry__pMn4c.PromotionBanner-module_deeplinkAmSignupflow__5bqmd {
    color: #fff;
    text-decoration: underline
}

.PromotionBar-module_container__fNeIz {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    bottom: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    padding: 6px 40px;
    box-shadow: 0 -2px 10px 0 rgba(0,0,0,.05);
    z-index: 102;
    cursor: pointer
}

.PromotionBar-module_container__fNeIz * {
    box-sizing: border-box
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_title__N2mGY {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: .7px;
    max-width: none;
    display: inline-block;
    flex: 2 1
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_promotionLogoContainer__ywlg3 {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    background-size: cover
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_amLogoBackground__7Dks7 {
    width: 40px;
    height: 40px;
    background-image: linear-gradient(0deg,#fa233b,#fb5c74);
    border-radius: 11px;
    display: flex;
    align-items: center;
    box-shadow: 1px 2.5px 4px 2px rgba(0,0,0,.06)
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_amLogoBackground__7Dks7 .PromotionBar-module_amLogo__o8QEm {
    width: 27px!important;
    height: 26px!important;
    display: block;
    margin: 7px 8px 7px 5px
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL {
    width: -moz-fit-content;
    width: fit-content
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_chevron__MG6EZ {
    display: none;
    height: 20px;
    width: 20px;
    transform: rotate(-90deg);
    margin-left: 4px
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL.PromotionBar-module_pressed__uhx1- {
    transform: scale(.98)
}

.PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_try__A1TPv {
    padding: 11px 20px!important;
    height: 40px
}

@media (min-width: 0px) {
    .PromotionBar-module_container__fNeIz {
        position:fixed;
        z-index: 102;
        padding: 8px 10px 8px 20px
    }

    .PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_try__A1TPv {
        display: none
    }

    .PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_chevron__MG6EZ {
        display: block
    }
}

@media (min-width: 640px) {
    .PromotionBar-module_container__fNeIz {
        position:fixed;
        z-index: 102;
        padding: 8px 40px
    }

    .PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_try__A1TPv {
        display: none
    }

    .PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_chevron__MG6EZ {
        display: block
    }
}

@media (min-width: 768px) {
    .PromotionBar-module_container__fNeIz {
        position:fixed;
        z-index: 102
    }

    .PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_try__A1TPv {
        display: block
    }

    .PromotionBar-module_container__fNeIz .PromotionBar-module_buttonContainer__YGUzL .PromotionBar-module_chevron__MG6EZ {
        display: none
    }
}

.PromotionBar-module_authExpiry__b3nNz.PromotionBar-module_deeplinkAmSignupflow__HCt-u {
    color: #fff;
    text-decoration: underline
}

.EventItem-post-module_container__FYmQA {
    position: relative;
    display: grid;
    grid-template-columns: 50px auto;
    grid-gap: 0 15px;
    padding: 20px 0;
    cursor: pointer;
    text-decoration: none
}

.EventItem-post-module_container__FYmQA:after {
    content: "";
    position: absolute;
    left: 65px;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: rgba(0,0,0,.1)
}

.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
    position: absolute;
    height: 100%;
    right: 0;
    display: flex;
    align-items: center
}

.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowIcon__e368G {
    width: 12px;
    height: 20px;
    fill: #d4d4d4
}

.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
    font-size: 12px;
    letter-spacing: .29px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
    font-size: 18px;
    align-self: flex-end;
    letter-spacing: .37px;
    margin-bottom: 5px;
    width: 90%
}

.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
    align-self: flex-start;
    letter-spacing: .29px;
    font-size: 14px
}

.EventItem-post-module_button__A4rLC {
    display: none
}

.EventItem-post-module_ellip__HVI0Y {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA {
    grid-template-columns: 80px 1fr 1fr auto;
    grid-gap: 5px 30px;
    margin: 0 -12px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 16px
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA:hover {
    background-color: rgba(0,136,255,.03)
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA:after {
    display: none
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46,.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
    grid-column-start: 2;
    grid-column-end: 3;
    margin-bottom: 0
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
    align-self: flex-end
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
    align-self: flex-start;
    font-size: 20px
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_button__A4rLC {
    display: block;
    visibility: hidden;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: center;
    background-color: var(--color-blue-300);
    border-radius: 8px;
    border: none;
    padding: 12px 20px;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
    border-radius: 18px;
    width: 80px;
    height: 80px;
    grid-row-start: 1;
    grid-row-end: 3
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
    font-size: 16px;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
    align-self: flex-start;
    margin-top: 3px
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA:hover .EventItem-post-module_button__A4rLC {
    text-decoration: none;
    visibility: visible
}

.EventItem-post-module_large__Fduat.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
    display: none
}

.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
    grid-row-start: 1;
    grid-row-end: 4;
    width: 45px;
    height: 45px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center
}

.EventItem-post-module_calendarIcon__9Y-qm:hover {
    text-decoration: none
}

.EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
    letter-spacing: .5px;
    text-transform: uppercase;
    font-size: 9px
}

.EventItem-post-module_large__Fduat .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
    font-size: 18px;
    letter-spacing: .5px;
    text-align: center
}

.EventItem-post-module_large__Fduat .EventItem-post-module_calendarIcon__9Y-qm {
    border-radius: 18px;
    width: 80px;
    height: 80px
}

.EventItem-post-module_large__Fduat .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
    font-size: 16px
}

.EventItem-post-module_large__Fduat .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
    font-size: 32px
}

@media screen and (min-width: 0px) {
    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA {
        grid-template-columns:80px 1fr 1fr auto;
        grid-gap: 5px 30px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 16px
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA:hover {
        background-color: rgba(0,136,255,.03)
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA:after {
        display: none
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46,.EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-bottom: 0
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
        align-self: flex-end
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        align-self: flex-start;
        font-size: 20px
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_button__A4rLC {
        display: block;
        visibility: hidden;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px;
        grid-row-start: 1;
        grid-row-end: 3
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
        font-size: 16px;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        align-self: flex-start;
        margin-top: 3px
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA:hover .EventItem-post-module_button__A4rLC {
        text-decoration: none;
        visibility: visible
    }

    .EventItem-post-module_xs-large__Mi7lQ.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
        display: none
    }

    .EventItem-post-module_xs-large__Mi7lQ .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 18px;
        letter-spacing: .5px;
        text-align: center
    }

    .EventItem-post-module_xs-large__Mi7lQ .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px
    }

    .EventItem-post-module_xs-large__Mi7lQ .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
        font-size: 16px
    }

    .EventItem-post-module_xs-large__Mi7lQ .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 32px
    }
}

@media screen and (min-width: 640px) {
    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA {
        grid-template-columns:80px 1fr 1fr auto;
        grid-gap: 5px 30px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 16px
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA:hover {
        background-color: rgba(0,136,255,.03)
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA:after {
        display: none
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46,.EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-bottom: 0
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
        align-self: flex-end
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        align-self: flex-start;
        font-size: 20px
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_button__A4rLC {
        display: block;
        visibility: hidden;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px;
        grid-row-start: 1;
        grid-row-end: 3
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
        font-size: 16px;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        align-self: flex-start;
        margin-top: 3px
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA:hover .EventItem-post-module_button__A4rLC {
        text-decoration: none;
        visibility: visible
    }

    .EventItem-post-module_sm-large__lyPM4.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
        display: none
    }

    .EventItem-post-module_sm-large__lyPM4 .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 18px;
        letter-spacing: .5px;
        text-align: center
    }

    .EventItem-post-module_sm-large__lyPM4 .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px
    }

    .EventItem-post-module_sm-large__lyPM4 .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
        font-size: 16px
    }

    .EventItem-post-module_sm-large__lyPM4 .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 32px
    }
}

@media screen and (min-width: 768px) {
    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA {
        grid-template-columns:80px 1fr 1fr auto;
        grid-gap: 5px 30px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 16px
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA:hover {
        background-color: rgba(0,136,255,.03)
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA:after {
        display: none
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46,.EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-bottom: 0
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
        align-self: flex-end
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        align-self: flex-start;
        font-size: 20px
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_button__A4rLC {
        display: block;
        visibility: hidden;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px;
        grid-row-start: 1;
        grid-row-end: 3
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
        font-size: 16px;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        align-self: flex-start;
        margin-top: 3px
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA:hover .EventItem-post-module_button__A4rLC {
        text-decoration: none;
        visibility: visible
    }

    .EventItem-post-module_md-large__aQnuF.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
        display: none
    }

    .EventItem-post-module_md-large__aQnuF .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 18px;
        letter-spacing: .5px;
        text-align: center
    }

    .EventItem-post-module_md-large__aQnuF .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px
    }

    .EventItem-post-module_md-large__aQnuF .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
        font-size: 16px
    }

    .EventItem-post-module_md-large__aQnuF .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 32px
    }
}

@media screen and (min-width: 1024px) {
    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA {
        grid-template-columns:80px 1fr 1fr auto;
        grid-gap: 5px 30px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 16px
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA:hover {
        background-color: rgba(0,136,255,.03)
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA:after {
        display: none
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46,.EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-bottom: 0
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
        align-self: flex-end
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        align-self: flex-start;
        font-size: 20px
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_button__A4rLC {
        display: block;
        visibility: hidden;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px;
        grid-row-start: 1;
        grid-row-end: 3
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
        font-size: 16px;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        align-self: flex-start;
        margin-top: 3px
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA:hover .EventItem-post-module_button__A4rLC {
        text-decoration: none;
        visibility: visible
    }

    .EventItem-post-module_lg-large__rcxWY.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
        display: none
    }

    .EventItem-post-module_lg-large__rcxWY .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 18px;
        letter-spacing: .5px;
        text-align: center
    }

    .EventItem-post-module_lg-large__rcxWY .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px
    }

    .EventItem-post-module_lg-large__rcxWY .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
        font-size: 16px
    }

    .EventItem-post-module_lg-large__rcxWY .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 32px
    }
}

@media screen and (min-width: 1280px) {
    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA {
        grid-template-columns:80px 1fr 1fr auto;
        grid-gap: 5px 30px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 16px
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA:hover {
        background-color: rgba(0,136,255,.03)
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA:after {
        display: none
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46,.EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-bottom: 0
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
        align-self: flex-end
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        align-self: flex-start;
        font-size: 20px
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_button__A4rLC {
        display: block;
        visibility: hidden;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px;
        grid-row-start: 1;
        grid-row-end: 3
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
        font-size: 16px;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        align-self: flex-start;
        margin-top: 3px
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA:hover .EventItem-post-module_button__A4rLC {
        text-decoration: none;
        visibility: visible
    }

    .EventItem-post-module_xl-large__lS02v.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
        display: none
    }

    .EventItem-post-module_xl-large__lS02v .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 18px;
        letter-spacing: .5px;
        text-align: center
    }

    .EventItem-post-module_xl-large__lS02v .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px
    }

    .EventItem-post-module_xl-large__lS02v .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
        font-size: 16px
    }

    .EventItem-post-module_xl-large__lS02v .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 32px
    }
}

@media screen and (min-width: 1536px) {
    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA {
        grid-template-columns:80px 1fr 1fr auto;
        grid-gap: 5px 30px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 16px
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA:hover {
        background-color: rgba(0,136,255,.03)
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA:after {
        display: none
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46,.EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-bottom: 0
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_date__Xuw46 {
        align-self: flex-end
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_name__OaQAq {
        align-self: flex-start;
        font-size: 20px
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_button__A4rLC {
        display: block;
        visibility: hidden;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px;
        grid-row-start: 1;
        grid-row-end: 3
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_address__zaqpN {
        font-size: 16px;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        align-self: flex-start;
        margin-top: 3px
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA:hover .EventItem-post-module_button__A4rLC {
        text-decoration: none;
        visibility: visible
    }

    .EventItem-post-module_xxl-large__eC2Sd.EventItem-post-module_container__FYmQA .EventItem-post-module_arrowLink__BokIN {
        display: none
    }

    .EventItem-post-module_xxl-large__eC2Sd .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 18px;
        letter-spacing: .5px;
        text-align: center
    }

    .EventItem-post-module_xxl-large__eC2Sd .EventItem-post-module_calendarIcon__9Y-qm {
        border-radius: 18px;
        width: 80px;
        height: 80px
    }

    .EventItem-post-module_xxl-large__eC2Sd .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_month__aeI4N {
        font-size: 16px
    }

    .EventItem-post-module_xxl-large__eC2Sd .EventItem-post-module_calendarIcon__9Y-qm .EventItem-post-module_day__uiNB- {
        font-size: 32px
    }
}

.NearbyEventMap-module_nearbyEventMap__ON0dh {
    background-color: #fff;
    border-radius: 16px;
    padding: 0
}

.NearbyEventMap-module_container__FwqRa {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    border-radius: 16px
}

.NearbyEventMap-module_mapImage__Z1KQH {
    width: 100%;
    height: 130px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 16px 16px 0 0
}

.NearbyEventMap-module_nearbyEventList__bPety {
    padding: 0 30px 30px;
    margin-bottom: 10px
}

.NearbyEventMap-module_mainTextStyle__z1NPk {
    text-transform: uppercase
}

.NearbyEventMap-module_infoContainer__ne6mr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 30px
}

@media (min-width: 768px) {
    .NearbyEventMap-module_container__FwqRa {
        display:grid;
        grid-template-columns: .4fr .7fr;
        grid-gap: 20px;
        gap: 20px;
        margin: 0;
        height: 100%;
        border-radius: 16px;
        padding: 30px
    }

    .NearbyEventMap-module_nearbyEventMap__ON0dh {
        background-color: #fff;
        border-radius: 16px
    }

    .NearbyEventMap-module_mapImage__Z1KQH {
        height: 180px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 6px
    }

    .NearbyEventMap-module_infoContainer__ne6mr {
        flex-grow: 1;
        padding: 0;
        height: 180px
    }

    .NearbyEventMap-module_nearbyEventList__bPety {
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 18px 30px
    }
}

.EventFilterList-module_container__A-fKH {
    max-width: 1128px
}

.EventFilterList-module_eventsListContainer__iwkI1 {
    background-color: #fff;
    border-radius: 16px;
    padding: 30px;
    margin-top: 10px
}

.EventFilterList-module_countryBtn__goNu2 {
    margin-top: 6px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1)
}

.Spinner-module_spinner__oetqk {
    width: 28px;
    height: 28px;
    display: inline-block;
    position: relative
}

.Spinner-module_spinner__oetqk.Spinner-module_sizesmall__4JjL9,.Spinner-module_spinner__oetqk.Spinner-module_sizesmall__4JjL9 svg {
    width: 15px;
    height: 15px
}

.Spinner-module_spinner__oetqk.Spinner-module_sizebase__vBHI9,.Spinner-module_spinner__oetqk.Spinner-module_sizebase__vBHI9 svg {
    width: 25px;
    height: 25px
}

.Spinner-module_spinner__oetqk.Spinner-module_sizelarge__nU2cJ,.Spinner-module_spinner__oetqk.Spinner-module_sizelarge__nU2cJ svg {
    width: 35px;
    height: 35px
}

.Spinner-module_spinner__oetqk.Spinner-module_sizexl__HtN8E,.Spinner-module_spinner__oetqk.Spinner-module_sizexl__HtN8E svg {
    width: 45px;
    height: 45px
}

@keyframes Spinner-module_waitindicator-animation__EnIlL {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

.Spinner-module_animation__EZ6Ey {
    animation-name: Spinner-module_waitindicator-animation__EnIlL;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%
}

.Spinner-module_fill__IOO0i {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 450px
}

.Spinner-module_relativeContainer__XxzMX {
    position: relative
}

.Spinner-module_absoluteContainer__hUuwu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.FullscreenVideo-module_absoluteParent__9MbgF {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    transition: transform .75s cubic-bezier(.4,.2,0,1);
    overflow: hidden;
    z-index: 2000
}

.FullscreenVideo-module_absoluteParent__9MbgF.FullscreenVideo-module_inView__9TlRi {
    transform: translateY(0)
}

.FullscreenVideo-module_video__hyehE {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.FullscreenVideo-module_description__eUNfW {
    position: absolute;
    left: 15px;
    top: 15px
}

.FullscreenVideo-module_dismiss__JSxO4 {
    display: flex;
    position: absolute;
    right: 15px;
    top: 77px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-width: 0;
    background-color: hsla(0,0%,100%,.2);
    align-items: center;
    justify-content: center;
    z-index: 2000;
    animation: FullscreenVideo-module_fade-in-kf__f3PoD 1.5s ease-in forwards;
    -webkit-animation: FullscreenVideo-module_fade-in-kf__f3PoD 1.5s ease-in forwards
}

@keyframes FullscreenVideo-module_fade-in-kf__f3PoD {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.FullscreenVideo-module_dismiss__JSxO4:hover {
    cursor: pointer
}

.FullscreenVideo-module_crossIcon__-1sP7 {
    display: flex;
    width: 12px;
    height: 12px;
    fill: #fff
}

.FullscreenVideo-module_artistName__rkkuF {
    opacity: .6
}

.Highlights-module_highlights__zs0Zd {
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    transition: transform .75s cubic-bezier(.4,.2,0,1);
    overflow: hidden;
    z-index: 9999
}

.Highlights-module_highlights__zs0Zd.Highlights-module_inView__LtFEU {
    transform: translateY(0)
}

.Highlights-module_highlightsVideoContainer__5gw5p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -62px;
    z-index: 101;
    overflow: hidden
}

.Highlights-module_highlightsVideoContainer__5gw5p video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: calc(50% - 62px);
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none
}

.Highlights-module_backButton__55FZZ {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    bottom: 0;
    z-index: 150
}

.Highlights-module_progressbar__Tq5F- {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 30px;
    padding: 15px;
    z-index: 150;
    transition: opacity .3s ease-in;
    pointer-events: none
}

.Highlights-module_progressbar__Tq5F- ul {
    justify-content: space-between;
    display: flex;
    padding: 0;
    max-width: var(--page-max-width);
    margin: 0 auto;
    list-style: none
}

.Highlights-module_progressbar__Tq5F- .Highlights-module_progress__nyRYu {
    height: 5px;
    border-radius: 2.5px;
    background: hsla(0,0%,83%,.3);
    position: relative;
    margin-right: 3px;
    flex: 1 1 100%;
    min-width: 0
}

.Highlights-module_progressbar__Tq5F- .Highlights-module_progressFill__OYd-D {
    position: absolute;
    top: 0;
    width: 0;
    height: 5px;
    border-radius: 2.5px;
    background: #fff
}

.Highlights-module_progressbar__Tq5F- .Highlights-module_progress__nyRYu.Highlights-module_playing__43poG .Highlights-module_progressFill__OYd-D {
    animation: Highlights-module_progressbar__Tq5F- 30s linear 0s 1 normal forwards
}

.Highlights-module_progress__nyRYu.Highlights-module_complete__ATjzq .Highlights-module_progressFill__OYd-D {
    width: 100%;
    background: #fff
}

.Highlights-module_titlebar__bz736 {
    margin-top: 40px;
    padding: 35px 15px;
    z-index: 150;
    position: relative
}

@keyframes Highlights-module_progressbar__Tq5F- {
    0% {
        width: 2px
    }

    to {
        width: 100%
    }
}

.Highlights-module_dismiss__HmuzT {
    display: flex;
    position: absolute;
    right: 15px;
    top: 77px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-width: 0;
    background-color: hsla(0,0%,100%,.2);
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation: Highlights-module_fade-in-kf__nqAqX 1.5s ease-in forwards;
    -webkit-animation: Highlights-module_fade-in-kf__nqAqX 1.5s ease-in forwards
}

@keyframes Highlights-module_fade-in-kf__nqAqX {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.Highlights-module_dismiss__HmuzT:hover {
    cursor: pointer
}

.Highlights-module_crossIcon__tVKzN {
    display: flex;
    width: 12px;
    height: 12px;
    fill: #fff
}

.Attribution-module_ellip__hAyxY {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.Attribution-module_title__xXphU {
    line-height: 1.75;
    letter-spacing: .5px;
    height: auto;
    width: auto;
    margin-bottom: 0
}

.ChartsBanner-module_container__9WjbY {
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.ChartsBanner-module_container__9WjbY * {
    box-sizing: border-box
}

@media (min-width: 768px) {
    .ChartsBanner-module_container__9WjbY {
        width:750px
    }
}

@media (min-width: 1024px) {
    .ChartsBanner-module_container__9WjbY {
        width:970px
    }
}

@media (min-width: 1280px) {
    .ChartsBanner-module_container__9WjbY {
        width:1170px
    }
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 {
    font-family: NeueMontreal,Arial,sans-serif;
    color: #0c0c0c;
    position: relative
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartContentsWrapper__vVjdr {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 16px;
    background-color: #e4e4e4;
    transition: background .3s ease;
    color: #fff;
    overflow: hidden
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartContentsWrapper__vVjdr:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.2))
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartContents__D-Twg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_trackImages__OUBgL {
    position: absolute;
    top: -233px;
    right: 113px;
    transform: rotate(-45deg)
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_trackImage__1TJTd {
    position: absolute;
    width: 160px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border: 1px solid hsla(0,0%,100%,.1)
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_trackImage__1TJTd.ChartsBanner-module_imgSrcSetLoading__AUYcW {
    background-color: transparent
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_trackImage__1TJTd:first-child {
    top: 170px;
    right: 170px
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_trackImage__1TJTd:nth-child(2) {
    top: 0;
    right: 170px
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_trackImage__1TJTd:nth-child(3) {
    top: 170px;
    right: 0
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_trackImage__1TJTd>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartInfo__3yhqu {
    color: #000;
    letter-spacing: .44px;
    padding-top: 15px
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartInfo__3yhqu .ChartsBanner-module_title__e6m5Y {
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 5px
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartInfo__3yhqu .ChartsBanner-module_desc__jaWbd {
    font-size: 16px;
    font-weight: 400;
    color: #646464
}

.ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartInfo__3yhqu .ChartsBanner-module_view__R1oeH {
    display: none;
    font-size: 16px;
    font-weight: 900;
    padding: 10px 30px;
    margin-left: 15px;
    text-transform: uppercase;
    background-color: hsla(0,0%,100%,.3);
    border-radius: 8px
}

@media screen and (min-width: 755px) {
    .ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartContentsWrapper__vVjdr {
        padding-top:330px
    }
}

@media screen and (min-width: 1128px) {
    .ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartInfo__3yhqu {
        color:#fff;
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 30px
    }

    .ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartInfo__3yhqu .ChartsBanner-module_view__R1oeH {
        display: block
    }

    .ChartsBanner-module_shzFrameHomeMorechartsChart__icGE7 .ChartsBanner-module_chartInfo__3yhqu .ChartsBanner-module_desc__jaWbd {
        color: #fff
    }
}

.ChartsBanner-module_grid__GHs3G {
    display: flex;
    padding: 0;
    max-width: var(--page-max-width);
    margin: 0 auto
}

.ChartsBanner-module_grid__GHs3G>* {
    flex: 1 1 100%;
    min-width: 0
}

.ChartsBanner-module_gridVertBottom__xla8u {
    align-items: flex-end
}

.ChartsBanner-module_grid__GHs3G .ChartsBanner-module_flexReset__TWUg4 {
    flex: none
}

.ArtistItem-module_medialistItem__FR6e4 {
    font-family: NeueMontreal,Arial,sans-serif;
    text-decoration: none
}

.ArtistItem-module_imageContainer__30KXh {
    width: 155px;
    height: 200px;
    border-radius: 8px;
    border: 1px solid hsla(0,0%,5%,.1);
    overflow: hidden
}

.ArtistItem-module_imageContainer__30KXh img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.ArtistItem-module_imageContainer__30KXh.ArtistItem-module_fallbackArtist__8Byk- {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: hsla(0,0%,5%,.4)
}

.ArtistItem-module_imageContainer__30KXh.ArtistItem-module_fallbackArtist__8Byk- svg {
    width: 72px;
    height: 82px
}

.ArtistItem-module_artistName__QsbqU {
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    color: #0c0c0c
}

.ArtistItem-module_subtitle__k3Nwu {
    margin-top: 6px
}

.ProgressCircle-module_progressCircleContainer__JAkIp {
    background-color: var(--color-white);
    border-radius: 16px;
    padding: 15px;
    text-align: center;
    gap: 5px
}

.ProgressCircle-module_descriptionContainer__XUjGa {
    display: grid;
    text-align: start;
    align-items: center
}

.ProgressCircle-module_content__A3OiB {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-gray-600)
}

.ProgressCircle-module_subtitle__CqhhN {
    margin-top: 5px!important
}

.ProgressCircle-module_footer__Upt7x {
    display: grid;
    align-items: baseline;
    padding: 0 10px;
    grid-template-columns: auto 3fr auto;
    grid-column-gap: 5px;
    margin-top: 10px;
    justify-items: flex-start
}

.ProgressCircle-module_svg__51k6G {
    transform: translateY(20%)
}

@media (max-width: 1120px) {
    .ProgressCircle-module_content__A3OiB {
        display:block
    }

    .ProgressCircle-module_descriptionContainer__XUjGa {
        text-align: center
    }
}
