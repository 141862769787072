
.typography-headline-reduced+p,
.typography-headline-reduced+ul,
.typography-headline-reduced+.typography-body {
    margin-top: 1em
}

.typography-site-body,
.typography-site-body-reduced,
.typography-site-body-alt,
.typography-site-tile {
    color: #86868b
}

.typography-headline-super {
    font-size: 80px;
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -.015em;
    font-family: var(--tile-font-family-secondary)
}

@media only screen and (max-width: 1068px) {
    .typography-headline-super {
        font-size: 64px;
        line-height: 1.0625;
        font-weight: 600;
        letter-spacing: -.009em;
        font-family: var(--tile-font-family-secondary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-headline-super {
        font-size: 48px;
        line-height: 1.08349;
        font-weight: 600;
        letter-spacing: -.003em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-headline-elevated {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-headline-elevated {
        font-size: 48px;
        line-height: 1.08349;
        font-weight: 600;
        letter-spacing: -.003em;
        font-family: var(--tile-font-family-secondary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-headline-elevated {
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        letter-spacing: 0em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-headline {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -.003em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-headline {
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        letter-spacing: 0em;
        font-family: var(--tile-font-family-secondary)
    }


}

@media only screen and (max-width: 734px) {
    .typography-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-headline-reduced {
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: 0em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-headline-reduced {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: var(--tile-font-family-secondary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-headline-reduced {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-eyebrow-reduced {
    font-size: 21px;
    line-height: 1.19048;
    font-weight: 600;
    letter-spacing: .011em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 734px) {
    .typography-eyebrow-reduced {
        font-size: 19px;
        line-height: 1.21053;
        font-weight: 600;
        letter-spacing: .012em;
        font-family: var(--tile-font-family-secondary)
    }
}

.typography-intro {
    font-size: 21px;
    line-height: 1.381;
    font-weight: 400;
    letter-spacing: .011em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 734px) {
    .typography-intro {
        font-size: 19px;
        line-height: 1.4211;
        font-weight: 400;
        letter-spacing: .012em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-callout {
    font-size: 32px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: .004em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-callout {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: var(--tile-font-family-secondary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-callout {
        font-size: 24px;
        line-height: 1.16667;
        font-weight: 600;
        letter-spacing: .009em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-manifesto {
    font-size: 32px;
    line-height: 1.25;
    font-weight: 600;
    letter-spacing: .004em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-manifesto {
        font-size: 28px;
        line-height: 1.28583;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: var(--tile-font-family-secondary)
    }


}

@media only screen and (max-width: 734px) {
    .typography-manifesto {
        font-size: 24px;
        line-height: 1.33341;
        font-weight: 600;
        letter-spacing: .009em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-label {
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 600;
    letter-spacing: .009em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-label {
        font-size: 21px;
        line-height: 1.19048;
        font-weight: 600;
        letter-spacing: .011em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-caption {
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: var(--tile-font-family-primary)
}


.typography-sosumi {
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: var(--tile-font-family-primary)
}


.typography-tiles-headline {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-tiles-headline {
        font-size: 48px;
        line-height: 1.08349;
        font-weight: 600;
        letter-spacing: -.003em;
        font-family: var(--tile-font-family-secondary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-tiles-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-facts-long-headline {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -.003em;
    font-family: var(--tile-font-family-secondary)
}

@media only screen and (max-width: 1068px) {
    .typography-facts-long-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: var(--tile-font-family-secondary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-facts-long-headline {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: var(--tile-font-family-secondary)
    }

}

[class*="typography-eyebrow"] {
    display: block;
    margin-bottom: .4em
}

[class*="typography-eyebrow"]+* {
    margin-top: 0
}

.typography-headline+.typography-intro {
    margin-top: 1.2em
}

.typography-headline-super+.typography-intro-elevated {
    margin-top: 1.6em
}

.typography-headline-elevated+.typography-intro-elevated {
    margin-top: 1.4em
}

.typography-headline-reduced+p,
.typography-headline-reduced+ul,
.typography-headline-reduced+.typography-body {
    margin-top: 1em
}


.typography-site-headline {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.009em;
    font-family: var(--tile-font-family-secondary)
}


@media only screen and (max-width: 1068px) {
    .typography-site-headline {
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        letter-spacing: 0em;
        font-family: var(--tile-font-family-secondary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-site-headline {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-site-subhead {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -.003em;
    font-family: var(--tile-font-family-secondary)
}

@media only screen and (max-width: 1068px) {
    .typography-site-subhead {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 600;
        letter-spacing: .004em;
        font-family: var(--tile-font-family-secondary)
    }
}

@media only screen and (max-width: 734px) {
    .typography-site-subhead {
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 600;
        letter-spacing: .007em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-site-headline-reduced {
    font-size: 28px;
    line-height: 1.14286;
    font-weight: 600;
    letter-spacing: .007em;
    font-family: var(--tile-font-family-secondary)
}



@media only screen and (max-width: 1068px) {
    .typography-site-headline-reduced {
        font-size: 24px;
        line-height: 1.16667;
        font-weight: 600;
        letter-spacing: .009em;
        font-family: var(--tile-font-family-secondary)
    }
}

@media only screen and (max-width: 734px) {
    .typography-site-headline-reduced {
        font-size: 21px;
        line-height: 1.19048;
        font-weight: 600;
        letter-spacing: .011em;
        font-family: var(--tile-font-family-secondary)
    }
}

.typography-site-body {
    font-size: 21px;
    line-height: 1.19048;
    font-weight: 600;
    letter-spacing: .011em;
    font-family: var(--tile-font-family-secondary)
}

@media only screen and (max-width: 1068px) {
    .typography-site-body {
        font-size: 17px;
        line-height: 1.29412;
        font-weight: 600;
        letter-spacing: -.022em;
        font-family: var(--tile-font-family-primary)
    }

}

@media only screen and (max-width: 734px) {
    .typography-site-body {
        font-size: 19px;
        line-height: 1.15798;
        font-weight: 600;
        letter-spacing: .012em;
        font-family: var(--tile-font-family-secondary)
    }

}

.typography-site-body-reduced {
    font-size: 14px;
    line-height: 1.42859;
    font-weight: 600;
    letter-spacing: -.016em;
    font-family: var(--tile-font-family-primary)
}

.typography-site-body-alt {
    font-size: 17px;
    line-height: 1.29412;
    font-weight: 600;
    letter-spacing: -.022em;
    font-family: var(--tile-font-family-primary)
}

@media only screen and (max-width: 1068px) {
    .typography-site-body-alt {
        font-size: 14px;
        line-height: 1.42859;
        font-weight: 600;
        letter-spacing: -.016em;
        font-family: var(--tile-font-family-primary)
    }
 
}

.typography-site-tile {
    font-size: 17px;
    line-height: 1.23536;
    font-weight: 600;
    letter-spacing: -.022em;
    font-family: var(--tile-font-family-primary)
}


@media only screen and (max-width: 734px) {
    .typography-site-tile {
        font-size: 17px;
        line-height: 1.23536;
        font-weight: 600;
        letter-spacing: -.022em;
        font-family: var(--tile-font-family-primary)
    }
}