
.section-content {
  margin-left: auto;
  margin-right: auto;
  width: 980px
}

@media only screen and (min-width: 1441px) {
  .section-content {
    margin-left:auto;
    margin-right: auto;
    width: 980px
  }
}

@media only screen and (max-width: 1068px) {
  .section-content {
    margin-left:auto;
    margin-right: auto;
    width: 692px
  }
}

@media only screen and (max-width: 734px) {
  .section-content {
    margin-left:auto;
    margin-right: auto;
    width: 87.5%
  }
}

@media only screen and (max-width: 374px) {
  .section-content {
    margin-left:auto;
    margin-right: auto;
    width: 90%
  }
}

@media only screen and (max-width: 734px) {
  .section-content {
    max-width:480px
  }
}
