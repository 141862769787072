body {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

[data-core-fade-transition-wrapper] {
  opacity: 0;
  transition-duration: .4s;
  transition-property: opacity;
  transition-timing-function: ease-in-out
}

.r-fade-transition-appear-active,
.r-fade-transition-enter-active,
.r-fade-transition-enter-done,
.r-fade-transition-exit {
  opacity: 1
}

.r-fade-transition-exit-active {
  opacity: 0
}

.r-fade-transition-exit-done {
  display: none
}

[data-core-height-transition-wrapper] {
  overflow: hidden;
  transition-duration: .4s;
  transition-property: height;
  transition-timing-function: ease-in-out
}

[data-core-height-transition-content] {
  overflow: hidden
}

.r-height-transition-exit-done {
  display: none
}

.r-height-transition-enter-done:not(.r-height-transition-enter-active) [data-core-height-transition-content],
.r-height-transition-enter-done:not(.r-height-transition-enter-active)[data-core-height-transition-wrapper] {
  overflow: visible
}

.rf-giftcard-buyflow {
  min-height: 760px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-buyflow {
      min-height: 400px
  }
}

.rf-giftcard-buyflow .rf-giftcard-selection-group {
  border-top: 1px solid #d2d2d7;
  padding: 21px 0 10px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-buyflow .rf-giftcard-selection-group {
      padding: 11px 0 10px
  }
}

.rf-giftcard-buyflow .rf-giftcard-selection-group.rf-giftcard-productselection {
  border-top: 0
}

.rf-giftcard-buyflow .rf-giftcard-selection-group:empty {
  border: 0;
  padding: 0
}

.rf-giftcard-sticky {
  height: 620px;
  position: sticky;
  top: 0
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-sticky {
      height: auto;
      position: static
  }
}

.rf-giftcard-sticky-content {
  box-sizing: border-box;
  margin: 54px 27px;
  padding-top: 22px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-sticky-content {
      margin: 20px 0 0;
      min-height: 264px;
      padding: 22px 0 0
  }
}

.rf-giftcard-sticky-content.rs-giftcard-electronic {
  background-color: #fafafc;
  padding: 64px 0 35px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-sticky-content.rs-giftcard-electronic {
      margin-bottom: 14px;
      padding: 30px 0 24px
  }
}

.rf-giftcard-sticky-content.rs-giftcard-electronic.rs-giftcard-preview-withmessage {
  padding-top: 36px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-sticky-content.rs-giftcard-electronic.rs-giftcard-preview-withmessage {
      padding-top: 26px
  }
}

.rf-giftcard-sticky-content.rs-giftcard-physical {
  background-color: #0000;
  margin: 59px 0;
  padding: 0
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-sticky-content.rs-giftcard-physical {
      margin: 20px 0 0;
      padding: 0
  }
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview {
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: .012em;
  line-height: 1.2105263158;
  margin: 0 auto;
  overflow: hidden;
  padding: 6px 28px 35px;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(ja) {
  font-family: SF Pro JP, SF Pro Display, SF Pro Icons, Hiragino Kaku Gothic Pro, ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, ï¼­ï¼³ ï¼°ã‚´ã‚·ãƒƒã‚¯, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.2631578947
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(ko) {
  font-family: SF Pro KR, SF Pro Display, SF Pro Icons, Apple Gothic, HY Gulim, MalgunGothic, HY Dotum, Lexi Gulim, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.3157894737
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(th) {
  font-family: SF Pro TH, SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.3684410526
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(zh) {
  line-height: 1.3684410526
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(ar) {
  font-family: SF Pro AR, SF Pro AR Display, SF Pro Display, SF Pro Gulf, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(zh-CN) {
  font-family: SF Pro SC, SF Pro Display, SF Pro Icons, PingFang SC, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(zh-HK) {
  font-family: SF Pro HK, SF Pro Display, SF Pro Icons, PingFang HK, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(zh-MO) {
  font-family: SF Pro HK, SF Pro TC, SF Pro Display, SF Pro Icons, PingFang HK, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-sticky-content .rf-giftcard-message-preview:lang(zh-TW) {
  font-family: SF Pro TC, SF Pro Display, SF Pro Icons, PingFang TC, Helvetica Neue, Helvetica, Arial, sans-serif
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-sticky-content .rf-giftcard-message-preview {
      padding: 0 20px 18px
  }
}


.rf-giftcard-image-preview {
  padding-bottom: 14px;
  text-align: center
}

.rf-giftcard-image-preview .rc-image-cross-fade.r-fade-transition-exit-active img {
  visibility: hidden
}

.rc-image-cross-fade {
  align-items: center;
  display: flex;
  justify-content: center
}

.rc-image-cross-fade[data-core-fade-transition-wrapper] {
  transition: opacity .3s ease-in-out
}

[data-core-overlay] {
  align-items: flex-start;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  inset-inline-end: 0;
  inset-inline-start: 0;
  overflow: auto;
  padding: 54px 0;
  position: fixed;
  top: 0;
  z-index: 99999;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

[data-core-overlay][data-core-fade-transition-wrapper] {
  transition-duration: .1s
}

[data-core-overlay].r-fade-transition-exit-done {
  display: none
}

[data-core-overlay-content] {
  background: #fff;
  margin: auto;
  padding: 30px;
  position: relative
}

[data-core-overlay-cover] {
  backface-visibility: visible;
  background: #0000007a;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

[data-core-overlay-fullscreen][data-core-overlay] {
  background-color: #fff;
  margin: 0;
  padding: 0
}

[data-core-overlay-fullscreen] [data-core-overlay-content] {
  border: none;
  margin: 0;
  max-width: none;
  min-height: 100vh;
  padding: 0;
  width: 100%
}

[data-core-overlay-fullscreen] [data-core-overlay-content]:focus {
  outline-offset: -4px
}

[data-core-overlay-open-ios-fixed],
[data-core-overlay-open] {
  overflow: hidden;
  position: relative
}

[data-core-overlay-open-ios-fixed] {
  bottom: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  position: fixed;
  top: 0
}

[data-core-overlay-cover].rc-blur {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px)
}

[data-core-overlay-content]:focus-visible {
  outline: none
}

[data-core-overlay-content]:focus-visible .rc-overlay-popup-outer {
  outline: 2px solid var(--sk-focus-color);
  outline-offset: var(--sk-focus-offset-container)
}


.rf-giftcard-main-image {
  height: 390px;
  padding-bottom: 30px;
  width: auto
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-main-image {
      height: 264px;
      margin: 13px auto 0
  }
}

.rs-giftcard-electronic .rf-giftcard-main-image {
  height: 243px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rs-giftcard-electronic .rf-giftcard-main-image {
      height: 162px
  }
}

.rs-giftcard-physical .rf-giftcard-main-image {
  height: 510px;
  padding: 0;
  width: auto
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rs-giftcard-physical .rf-giftcard-main-image {
      height: 341px
  }
}

.rs-giftcard-physical.rs-giftcard-preview-withmessage .rf-giftcard-main-image {
  height: 333px;
  width: auto
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rs-giftcard-physical.rs-giftcard-preview-withmessage .rf-giftcard-main-image {
      height: 341px
  }
}

.rf-giftcard-header-main {
  padding: 46px 0 18px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-header-main {
      padding: 23px 0 10px;
      text-align: center
  }
}

.rf-giftcard-header-main h1 {
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.1
}

.rf-giftcard-header-main h1:lang(ja) {
  font-family: SF Pro JP, SF Pro Display, SF Pro Icons, Hiragino Kaku Gothic Pro, ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, ï¼­ï¼³ ï¼°ã‚´ã‚·ãƒƒã‚¯, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.175
}

.rf-giftcard-header-main h1:lang(ko) {
  font-family: SF Pro KR, SF Pro Display, SF Pro Icons, Apple Gothic, HY Gulim, MalgunGothic, HY Dotum, Lexi Gulim, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.2
}

.rf-giftcard-header-main h1:lang(th) {
  font-family: SF Pro TH, SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.35
}

.rf-giftcard-header-main h1:lang(ar) {
  font-family: SF Pro AR, SF Pro AR Display, SF Pro Display, SF Pro Gulf, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-header-main h1:lang(zh-CN) {
  font-family: SF Pro SC, SF Pro Display, SF Pro Icons, PingFang SC, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-header-main h1:lang(zh-HK) {
  font-family: SF Pro HK, SF Pro Display, SF Pro Icons, PingFang HK, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-header-main h1:lang(zh-MO) {
  font-family: SF Pro HK, SF Pro TC, SF Pro Display, SF Pro Icons, PingFang HK, Helvetica Neue, Helvetica, Arial, sans-serif
}

.rf-giftcard-header-main h1:lang(zh-TW) {
  font-family: SF Pro TC, SF Pro Display, SF Pro Icons, PingFang TC, Helvetica Neue, Helvetica, Arial, sans-serif
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-giftcard-header-main h1 {
      font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: .007em;
      line-height: 1.1428571429
  }

  .rf-giftcard-header-main h1:lang(ja) {
      font-family: SF Pro JP, SF Pro Display, SF Pro Icons, Hiragino Kaku Gothic Pro, ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, ï¼­ï¼³ ï¼°ã‚´ã‚·ãƒƒã‚¯, Helvetica Neue, Helvetica, Arial, sans-serif;
      line-height: 1.1786114286
  }

  .rf-giftcard-header-main h1:lang(ko) {
      font-family: SF Pro KR, SF Pro Display, SF Pro Icons, Apple Gothic, HY Gulim, MalgunGothic, HY Dotum, Lexi Gulim, Helvetica Neue, Helvetica, Arial, sans-serif;
      line-height: 1.25
  }

  .rf-giftcard-header-main h1:lang(th) {
      font-family: SF Pro TH, SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
      line-height: 1.3928571429
  }

  .rf-giftcard-header-main h1:lang(zh) {
      line-height: 1.25
  }

  .rf-giftcard-header-main h1:lang(ar) {
      font-family: SF Pro AR, SF Pro AR Display, SF Pro Display, SF Pro Gulf, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif
  }

  .rf-giftcard-header-main h1:lang(zh-CN) {
      font-family: SF Pro SC, SF Pro Display, SF Pro Icons, PingFang SC, Helvetica Neue, Helvetica, Arial, sans-serif
  }

  .rf-giftcard-header-main h1:lang(zh-HK) {
      font-family: SF Pro HK, SF Pro Display, SF Pro Icons, PingFang HK, Helvetica Neue, Helvetica, Arial, sans-serif
  }

  .rf-giftcard-header-main h1:lang(zh-MO) {
      font-family: SF Pro HK, SF Pro TC, SF Pro Display, SF Pro Icons, PingFang HK, Helvetica Neue, Helvetica, Arial, sans-serif
  }

  .rf-giftcard-header-main h1:lang(zh-TW) {
      font-family: SF Pro TC, SF Pro Display, SF Pro Icons, PingFang TC, Helvetica Neue, Helvetica, Arial, sans-serif
  }
}

.rf-product-selection {
  padding: 10px 0
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-product-selection {
      padding: 0
  }
}

.rf-product-selection .rf-productselection-dimension-header .selection-title {
  font-weight: 600
}

.rf-product-selection-dimension {
  padding-bottom: 11px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-product-selection-dimension {
      padding-bottom: 0
  }
}

.rf-product-selection-dimension .rf-product-selection-hideoption {
  display: none
}

.rf-product-selection-dimension .rf-product-selection-dimimage {
  height: 77px;
  max-width: 165px;
  width: auto
}

.rf-product-selection-dimension .rc-dimension-selector-group {
  margin-top: 0
}

.rf-product-selection-content {
  border-top: 1px solid #d2d2d7;
  margin-top: 21px;
  padding-top: 22px
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-product-selection-content {
      margin-top: 10px;
      padding-bottom: 21px
  }
}

.rf-product-selection-content:first-child {
  border: 0;
  margin-top: 0;
  padding: 0
}

@media only screen and (max-width:734px)and (max-device-width:736px) {
  .rf-product-selection-content:first-child {
      padding-bottom: 21px
  }
}

.rf-product-selection-content.rf-product-selection-disabled {
  border: 0;
  margin-top: 0;
  padding: 0
}

.form-selector-group-withgutters {
  list-style: none;
  margin-top: 8px;
  margin-inline-end: -.4117647059rem;
  margin-inline-start: -.4117647059rem;
  width: auto
}

.form-selector-group-withgutters .form-selector {
  margin-top: .8235294118rem
}

.form-selector-group-withgutters .form-selector,
.form-selector-group-withgutters .form-selector.form-selector-circle {
  padding-inline-end: .4117647059rem;
  padding-inline-start: .4117647059rem
}

.form-selector-group-withgutters~.form-message-wrapper {
  margin-top: 14px
}

.rf-choose-amount {
  padding-bottom: 20px
}

.rf-choose-amount fieldset {
  min-width: unset
}

.rf-choose-amount.rf-choose-amount-disabled {
  opacity: .3
}

.rf-choose-amount.rf-choose-amount-disabled .rf-selector-button:disabled {
  opacity: 1
}

.rf-choose-amount .rf-choose-amount-dimensions {
  margin-top: 0
}

.rf-choose-amount .is-error legend {
  color: #e30000
}

.rf-choose-amount-section-title {
  font-weight: 600
}

.rf-choose-amount-label {
  overflow: hidden
}

.rf-selector-button-custom.rf-selector-button-selected .rf-choose-amount-label {
  color: #0071e3
}




.shz-partial-medialist-controls {
  display: none
}

.shz-partial-medialist-controls>button {
  background-color: #08f;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  color: #fff;
  appearance: none;
  -webkit-appearance: none
}

.shz-partial-medialist-controls button>span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%
}

.shz-partial-medialist-controls>button[disabled] {
  background-color: #e4e4e4
}

.shz-partial-medialist-controls>button.button-left {
  margin-right: 10px
}

.shz-partial-medialist-controls>button[data-shz-cmd] svg {
  fill: #fff;
  width: 18px;
  height: 18px
}

.shz-partial-medialist-controls>button.button-left svg {
  transform: rotate(90deg) translateY(1px) scale(.8)
}

.shz-partial-medialist-controls>button.button-right svg {
  transform: rotate(-90deg) translateY(1px) scale(.8)
}

@media (hover:hover) {
  .shz-partial-medialist-controls {
      display: flex;
      justify-content: flex-end;
      max-width: 1370px;
      margin: 5px auto 0 auto
  }
}

.shz-partial-medialist-trackam {
  min-width: 85%;
  padding-right: 15px
}

.shz-partial-medialist-trackam:last-child {
  min-width: 100%
}

.shz-partial-medialist-trackam .media.track {
  position: relative;
  padding: 15px 0
}

.shz-partial-medialist-trackam .media.track:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 1px;
  background-color: rgba(0, 0, 0, .1)
}

.shz-partial-medialist-trackam .media.track.with-count:after {
  left: 26px
}

.shz-partial-medialist-trackam .media.track:nth-child(3n):after {
  content: none
}

.shz-partial-medialist-trackam .media.track .count {
  position: relative;
  width: 25px;
  align-self: center;
  justify-self: center;
  font-weight: 700
}

.shz-partial-medialist-trackam .media.track .image-container {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #efefef
}

.shz-partial-medialist-trackam .media.track .image-container img {
  width: 100%;
  height: 100%
}

.shz-partial-medialist-trackam .media.track .image-container .album-art {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center
}

.shz-partial-medialist-trackam .media.track .audio-play {
  position: relative
}

.shz-partial-medialist-trackam .media.track .count {
  text-align: center;
  width: 20px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 16px
}

.shz-partial-medialist-trackam .media.track .shz-partial-audio {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0)
}

.shz-partial-medialist-trackam .media.track .track-info {
  position: relative;
  padding-left: 15px;
  align-self: flex-start
}

.shz-partial-medialist-trackam .media.track .title {
  position: relative;
  display: inline-block;
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #242424;
  margin-top: 0;
  margin-bottom: 0
}

.shz-partial-medialist-trackam .media.track .title.explicit-mark {
  padding-right: 25px
}

.shz-partial-medialist-trackam .media.track .title.explicit-mark:after {
  bottom: 3px
}

.shz-partial-medialist-trackam .media.track .subtitle {
  display: block;
  color: #646464;
  font-size: 16px;
  font-weight: 400
}

.shz-partial-medialist-trackam .media.track .number-of-shazams {
  display: none
}

.shz-partial-medialist-trackam .media.track .more {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  display: flex
}

.shz-partial-medialist-trackam .media.track .more .ampill {
  flex: 1 1 100%;
  padding-left: 91px
}

.shz-partial-medialist-trackam .media.track.with-count .more .ampill {
  padding-left: 117px
}

.shz-partial-medialist-trackam .media.track .more a.popup .icon-more {
  height: 19px;
  width: 19px;
  fill: #949494
}

@media (min-width:768px) {
  .shz-partial-medialist-trackam {
      min-width: calc(50% - 30px);
      padding-right: 50px
  }

  .medialist-container .shz-partial-medialist-trackam.medialist-item:last-child,
  .shz-partial-medialist-trackam:last-child {
      padding-right: 50%;
      min-width: 100%
  }

  .shz-partial-medialist-trackam .media.track {
      padding: 15px 0 15px 15px
  }

  .shz-partial-medialist-trackam .media.track:hover {
      border-radius: 8px;
      background-color: rgba(0, 136, 255, .03)
  }

  .shz-partial-medialist-trackam .media.track:after {
      left: 15px;
      right: 7px
  }

  .shz-partial-medialist-trackam .media.track.with-count:after {
      left: 41px
  }

  .shz-partial-medialist-trackam .media.track .track-info {
      align-self: center
  }

  .shz-partial-medialist-trackam .media.track .title {
      margin-bottom: 5px
  }

  .shz-partial-medialist-trackam .media.track .number-of-shazams {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: .02px;
      color: #949494;
      opacity: 1;
      transition: opacity .3s ease-out
  }

  .shz-partial-medialist-trackam .media.track .number-of-shazams svg {
      height: 20px;
      width: 20px;
      fill: #949494;
      vertical-align: middle;
      margin-right: 5px
  }

  .shz-partial-medialist-trackam .media.track .more {
      display: flex;
      justify-content: flex-end;
      opacity: 0;
      transition: opacity .3s ease-out;
      position: static;
      left: 0;
      right: 0;
      bottom: 0
  }

  .shz-partial-medialist-trackam .media.track .more .ampill,
  .shz-partial-medialist-trackam .media.track.with-count .more .ampill {
      padding-left: 0;
      margin-right: 12px
  }

  .shz-partial-medialist-trackam .media.track:focus .number-of-shazams,
  .shz-partial-medialist-trackam .media.track:hover .number-of-shazams {
      opacity: 0
  }

  .shz-partial-medialist-trackam .media.track:focus .more,
  .shz-partial-medialist-trackam .media.track:hover .more {
      opacity: 1
  }

  .shz-partial-medialist-trackam .media.track .more a.ampill {
      margin: 0 15px 0 0
  }
}

.shz-partial-event-list-items {
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
  text-align: left
}

.shz-partial-event-list-items .event:last-child .city {
  border: none
}

.shz-partial-event-list-items .event {
  position: relative;
  display: grid;
  grid-template-columns: 50px 1fr auto;
  grid-gap: 0 15px;
  padding: 20px 0
}

.shz-partial-event-list-items .event:after {
  content: '';
  position: absolute;
  left: 65px;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, .1)
}

.shz-partial-event-list-items .event:last-child:after {
  content: none
}

.shz-partial-event-list-items .date-str {
  opacity: .5;
  font-size: 12px;
  letter-spacing: .29px;
  color: #000;
  margin-bottom: 5px
}

.shz-partial-event-list-items .name {
  align-self: flex-end;
  font-size: 18px;
  letter-spacing: .37px;
  font-weight: 700;
  margin-bottom: 5px
}

.shz-partial-event-list-items .city {
  align-self: flex-start;
  font-size: 14px;
  letter-spacing: .29px;
  color: #000
}

.shz-partial-event-list-items .country {
  display: none
}

.shz-partial-event-list-items .cal-date {
  grid-row-start: 1;
  grid-row-end: 4;
  width: 45px;
  height: 45px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
  border: solid 1px rgba(0, 0, 0, .05);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center
}

.shz-partial-event-list-items .cal-date .month {
  color: #fa243c;
  font-size: 9px;
  letter-spacing: .5px;
  text-transform: uppercase
}

.shz-partial-event-list-items .cal-date .day {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: .5px;
  text-align: center;
  color: #000
}

.shz-partial-event-list-items .cal-date svg {
  width: 24px;
  height: 20px;
  fill: #000
}

.shz-partial-event-list-items .view-event {
  display: none
}

.shz-partial-event-list-items .arrow {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 3;
  grid-column-end: 4;
  align-self: center;
  width: 12px;
  height: 20px;
  fill: #d4d4d4;
  transform: rotate(-90deg)
}

@media (min-width:800px),
(max-width:800px) and (max-height:500px) {
  .shz-partial-event-list-items .event {
      grid-template-columns: 80px 1fr 1fr auto;
      grid-gap: 5px 30px;
      margin: 0 -12px;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 16px
  }

  .shz-partial-event-list-items .event:hover {
      background-color: rgba(0, 136, 255, .03)
  }

  .shz-partial-event-list-items .event:after {
      left: 122px;
      right: 12px
  }

  .shz-partial-event-list-items .cal-date {
      border-radius: 18px;
      width: 80px;
      height: 80px;
      grid-row-start: 1;
      grid-row-end: 3
  }

  .shz-partial-event-list-items .cal-date .month {
      font-size: 16px
  }

  .shz-partial-event-list-items .cal-date .day {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: .5px;
      text-align: center;
      color: #000
  }

  .shz-partial-event-list-items .date-str,
  .shz-partial-event-list-items .name {
      grid-column-start: 2;
      grid-column-end: 3
  }

  .shz-partial-event-list-items .date-str {
      align-self: flex-end;
      margin-bottom: 0
  }

  .shz-partial-event-list-items .name {
      align-self: flex-start;
      font-size: 20px;
      margin-bottom: 0
  }

  .shz-partial-event-list-items .city,
  .shz-partial-event-list-items .view-event {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 3;
      grid-column-end: 4;
      align-self: center
  }

  .shz-partial-event-list-items .city {
      font-size: 16px;
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 3;
      grid-column-end: 4;
      align-self: flex-start;
      margin-top: 3px
  }

  .shz-partial-event-list-items .country {
      display: inline
  }

  .shz-partial-event-list-items .view-event {
      display: block;
      visibility: hidden;
      grid-column-start: 4;
      grid-column-end: 5;
      background-color: #08f;
      border-radius: 8px;
      border: none;
      padding: 12px 20px;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase
  }

  .shz-partial-event-list-items .view-event:hover {
      text-decoration: none
  }

  .shz-partial-event-list-items .event:hover .view-event {
      visibility: visible
  }

  .shz-partial-event-list-items .arrow {
      display: none
  }
}



.shz-partial-medialist-track {
  min-width: 85%
}

.shz-partial-medialist-track:last-child {
  min-width: 100%
}

.shz-partial-medialist-track .media.track {
  position: relative;
  padding: 11px 0 9px 0;
  border-radius: 8px;
  overflow: hidden
}

.shz-partial-medialist-track .media.track:hover {
  background-color: rgba(0, 136, 255, .03)
}

.shz-partial-medialist-track .media.track:nth-child(3n) {
  border-bottom: none
}

.shz-partial-medialist-track .media.track .count {
  text-align: center;
  width: 20px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 16px
}

.shz-partial-medialist-track .media.track .image-container {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden
}

.shz-partial-medialist-track .media.track .image-container img {
  width: 100%;
  height: 100%
}

.shz-partial-medialist-track .media.track .image-container .album-art {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center
}

.shz-partial-medialist-track .media.track .shz-partial-audio {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0)
}

.shz-partial-medialist-track .media.track .track-info {
  position: relative;
  top: -15px;
  padding-left: 10px
}

.shz-partial-medialist-track .media.track .title {
  position: relative;
  display: inline-block;
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #242424
}

.shz-partial-medialist-track .media.track .title.explicit-mark {
  padding-right: 25px
}

.shz-partial-medialist-track .media.track .title.explicit-mark:after {
  bottom: 3px
}

.shz-partial-medialist-track .media.track .subtitle {
  display: block;
  color: #646464;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px
}

.shz-partial-medialist-track .media.track .number-of-shazams {
  display: none
}

.shz-partial-medialist-track .media.track .more {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 9px;
  display: flex
}

.shz-partial-medialist-track .media.track .more .ampill {
  flex: 1 1 100%;
  padding-left: 120px
}

.shz-partial-medialist-track .media.track .more a.popup .icon-more {
  height: 19px;
  width: 19px;
  fill: #949494
}

@media (min-width:768px) {
  .shz-partial-medialist-track {
      min-width: calc(50% - 30px);
      padding-right: 50px
  }

  .medialist-container .shz-partial-medialist-track.medialist-item:last-child,
  .shz-partial-medialist-track:last-child {
      padding-right: 50%
  }

  .shz-partial-medialist-track .media.track .track-info {
      top: 0
  }

  .shz-partial-medialist-track .media.track:focus .track-info,
  .shz-partial-medialist-track .media.track:hover .track-info {
      padding-right: 120px
  }

  .shz-partial-medialist-track .media.track.has-number-of-shazams .track-info {
      padding-right: 20px
  }

  .shz-partial-medialist-track .media.track.has-number-of-shazams:focus .track-info,
  .shz-partial-medialist-track .media.track.has-number-of-shazams:hover .track-info {
      padding-right: 40px
  }

  .shz-partial-medialist-track .media.track .title {
      margin-bottom: 5px
  }

  .shz-partial-medialist-track .media.track .number-of-shazams {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: .02px;
      color: #949494;
      opacity: 1;
      transition: opacity .3s ease-out
  }

  .shz-partial-medialist-track .media.track .number-of-shazams svg {
      height: 20px;
      width: 20px;
      fill: #949494;
      vertical-align: middle;
      margin-right: 5px
  }

  .shz-partial-medialist-track .media.track .more {
      display: flex;
      justify-content: flex-end;
      opacity: 0;
      transition: opacity .3s ease-out;
      position: absolute;
      left: auto;
      right: 22px;
      bottom: auto
  }

  .shz-partial-medialist-track .media.track .more .ampill {
      padding-left: 0;
      margin-right: 12px
  }

  .shz-partial-medialist-track .media.track:focus .number-of-shazams,
  .shz-partial-medialist-track .media.track:hover .number-of-shazams {
      opacity: 0
  }

  .shz-partial-medialist-track .media.track:focus .more,
  .shz-partial-medialist-track .media.track:hover .more {
      opacity: 1
  }

  .shz-partial-medialist-track .media.track .more a.ampill {
      margin: 0 15px 0 0
  }
}


.shz-partial-showmoreless {
  padding: 30px 0 0 0;
  text-align: center
}

.shz-partial-showmoreless .shz-text-btn {
  width: fit-content !important
}

.shz-partial-showmoreless.hide,
.shz-ux-showmoreless .hide {
  display: none !important
}


.shz-partial-videos {
  position: relative
}

.shz-partial-videos .vd-list {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap
}

.shz-partial-videos .vd-elm {
  display: inline-block;
  width: 215px;
  margin-right: 10px;
  color: #242424;
  overflow: hidden;
  vertical-align: top
}

.shz-partial-videos .vd-elm.placeholder {
  display: none
}

.shz-partial-videos .vd-data {
  margin: 15px 0 6px
}

.shz-partial-videos .vd-elm .vd-data p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.shz-partial-videos .vd-title {
  color: #242424;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 4px
}

.shz-partial-videos .vd-details {
  font-size: 16px;
  color: #b4b4b4
}

.shz-partial-videos .vd-box {
  position: relative;
  width: 100%;
  height: 161px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center
}

.shz-partial-videos .vd-box .vd-play {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%
}

.shz-partial-videos .panel-bd {
  padding: 10px
}

.shz-partial-videos .img-audio-play {
  position: absolute;
  height: 35px;
  width: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -17px;
  border-radius: 8px
}

.shz-partial-videos .banner a {
  display: inline-block;
  width: 100%;
  height: 100%
}

@media (max-width:650px) {
  .shz-partial-videos {
      min-height: 209px
  }

  .shz-partial-videos .vd-elm {
      width: 194px
  }

  .shz-partial-videos .vd-box {
      height: 146px
  }

  .shz-partial-videos .vd-list {
      min-width: 100%;
      padding: 0 5%
  }

  .shz-partial-videos .vd-list li:last-child {
      margin-right: 0
  }
}

@media (min-width:650px) {
  .shz-partials-videos {
      min-height: 258px
  }
}








.scheda-assunzioni-list-items {
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
  text-align: left;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.scheda-assunzioni-list-item {
  position: relative;
  display: grid;
  grid-template-columns: 50px 1fr auto;
  grid-gap: 0 15px;
  padding: 20px 0;
}

.scheda-assunzioni-list-item:after {
  content: '';
  position: absolute;
  left: 65px;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: rgba(0,0,0,.1);
  box-sizing: border-box;
}

.scheda-assunzioni-list-item .cal-date {
  grid-row-start: 1;
  grid-row-end: 4;
  width: 45px;
  height: 45px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
  border: solid 1px rgba(0,0,0,.05);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.scheda-assunzioni-list-item .cal-date .month {
  color: #fa243c;
  font-size: 9px;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.scheda-assunzioni-list-item .cal-date .day {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: .5px;
  text-align: center;
  color: #000;
}

.scheda-assunzioni-list-item .date-str {
  opacity: .5;
  font-size: 12px;
  letter-spacing: .29px;
  color: #000;
  margin-bottom: 5px;
}

.scheda-assunzioni-list-item .name {
  align-self: flex-end;
  font-size: 18px;
  letter-spacing: .37px;
  font-weight: 700;
  margin-bottom: 5px;
}

.scheda-assunzioni-list-item .ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scheda-assunzioni-list-item .quantity-hour {
  align-self: flex-start;
  font-size: 14px;
  letter-spacing: .29px;
  color: #000;
}

.scheda-assunzioni-list-item .arrow {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 3;
  grid-column-end: 4;
  align-self: center;
  width: 12px;
  height: 20px;
  fill: #d4d4d4;
  transform: rotate(-90deg);
}

.webview-bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #fff;
}

.button-lavoro {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
}

.button-lavoro {
  width: 100%;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 12px;
  padding: 14px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom,rgba(255,255,255,.3),rgba(255,255,255,0));
  background-image: linear-gradient(to bottom,#fff,#fff),linear-gradient(to bottom,rgba(255,255,255,.3),rgba(255,255,255,0));
}

.webview-post-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 390px;
  padding-bottom: 20px;
  overflow: hidden;
}

.webview-post-header {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  padding-top: 14px;

}

.webview-post-header-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.webview-post-header-avatar {
  margin-right: 12px;
}

.webview-post-header-avatar-content {
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.webview-post-header-avatar-img{
  width: 100%;
  height: 100%;
}

.webview-post-header-name span {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.webview-post-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.webview-post-body-media {
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

.webview-post-body-media-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.webview-post-footer {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 10px;
}


.posts-content {
  padding-top: calc(var(--posts-quantity) * 690px) ;
}

@media screen and (max-width: 724px) {
  .posts-content {
    padding-top: calc(var(--posts-quantity) * 600px) ;
  }
}